/*You can add global styles to this file, and also import other style files*/ 
@import "~@ng-select/ng-select/themes/default.theme.css";


#main {
}

.c-offcanvas {
  top: 9vh !important;
}



.panelVesta {
  background-color: #E8F1F4;
  border: 1px solid #647687;
  padding: 4px 15px 4px 15px;
  margin-bottom: 20px;
}

.buttonTransparente {
  background-color: transparent;
  border: none;
}

.row {
  margin-bottom: 10px !important;
}

.set-dialog-content {
  overflow-y: scroll;
  height: 90vh !important;
}

.dialog {
  z-index: 2999;
}

.notify-container {
  z-index: 3000 !important;
}

.contentDialogPrincipal {
  height: 477px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  overflow-y: scroll;
}

.contentDialog iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.contentDialogPrincipal .contentDinamico {
  border: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-wrap: normal;
}

.contentDialog .contentDinamico {
  border: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  overflow-wrap: normal;
}

.contentDialog .contentDinamicoIframe {
  border: 0;
  width: 100%;
  height: 100%;
  overflow-wrap: normal;
}

.headerDialog {
  height: 40px;
  background-color: black;
  text-align: center;
  color: white;
  padding-top: 3px;
}


.buttonA {
  padding: 0 1rem;
  height: 2.125rem;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px #d9d9d9 solid;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  outline: none;
  font-size: .875rem;
  margin: .15625rem 0;
  position: relative;
}

  .buttonA:hover {
    border-color: #787878;
  }

  .buttonA:active {
    background: #eeeeee;
    color: #262626;
    box-shadow: none;
  }

  .buttonA:focus {
    outline: 0;
  }

.warningVesta {
  background-color: #e3c800;
  color: white;
}

  .warningVesta:hover {
    border-color: #e3c800;
  }

  .warningVesta:active {
    background-color: #ffca28;
    color: white;
  }

.IconGrid {
  left: 84%;
}

.input-control {
  margin: 0 !important;
}

@media(max-width:920px) {
  .IconGrid {
    left: 76%;
  }
}

.invalid_feedback {
  font-size: .9em;
  color: #CE352C;
}

.float-right {
  float: right !important;
}

select {
  height: 28px;
}

.place-left,
.float-left {
  float: left !important;
}

.mydp {
  border-radius: 0px !important;
}

.c-btn {
  box-shadow: 0 0 0 0 !important;
  border-radius: 0px !important;
}

.dialog .dialog-title {
  font-size: 1.125rem;
}

  .dialog .dialog-title + .dialog-content {
    margin: 20px;
  }

.list-area {
  border-radius: 0px !important;
  box-shadow: 0 0 0 !important;
}

.dialog-ex {
  z-index: 2000 !important;
}

.dialog-actions .button {
  margin-left: 22%;
  margin-right: 25px;
}

.dialog-actions {
  border-top: 2px #1565c0 solid;
}

.dialog-ex {
  border: 1px #1565c0 solid;
}

.c-token {
  height: 20px !important;
}


.card {
  display: block;
  position: relative;
  font-size: 0.875rem;
  width: calc(100% - 1rem);
  margin: 8px auto;
  border-radius: 2px;
  border: 1px solid #dfdfdf;
  z-index: 0;
}

.card-body button {
  z-index: 1000;
}

.card-header {
  border-bottom: 1px #eee solid;
  padding: 1rem;
  font-size: 16px;
  border-radius: 2px 2px 0 0;
  display: block;
}

  .card-header .avatar {
    float: left;
    width: 2.75rem;
    height: 2.75rem;
    overflow: hidden;
  }

    .card-header .avatar img {
      width: 100%;
      min-height: 100%;
    }

  .card-header .name {
    margin-left: 3.375rem;
    font-weight: 500;
  }

  .card-header .date {
    margin-left: 3.375rem;
    font-size: 0.8125rem;
    font-weight: 500;
    color: #bebebe;
  }

.card-content {
  display: block;
}

.card-footer {
  border-top: 1px #eee solid;
  padding: 1rem;
  border-radius: 0 0 2px 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 3rem;
}

.card.image-header .card-header {
  height: 9rem;
  vertical-align: bottom;
  background-size: cover;
  background-position: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

* + .card {
  margin-top: 1rem;
}

.tile-small,
.tile-medium,
.tile-wide,
.tile-large,
.tile-app {
  display: block;
  background-color: #1ba1e2;
  color: #ffffff;
  width: 150px;
  height: 150px;
  -webkit-box-shadow: inset 0 0 1px #FFFFCC;
  box-shadow: inset 0 0 1px #FFFFCC;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wc-date-container {
  border: 1px solid #070a0c !important;
}

  .wc-date-container span {
    color: black !important;
  }

  .wc-date-container i {
    color: black !important;
  }

.mydrp {
  width: 100% !important;
}

.imagenFondo {
    background-image: url("/images/fondo5.jpg");
    /*background-repeat: repeat-x;*/
}


.tittleOpMenuFavorite {
    height: 32px;
    position: absolute;
    top: 26px;
    font-size: 11px;
    right: 9px;
    line-height: 13px;
    word-break: break-word;
    padding: 3px 1px;
    width: 90%;
    font-weight: 600;
}

.divOpMenu{
    height:130px;
    width:130px;
}

.iconOpMenuFavorite {
    max-width: 33%;
    height: 33%;
    position: absolute;
    top: 75%;
    left: 25%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    font-size: 50px;
    line-height: 50px;
    z-index: 2;
}

.multi-action {
    display: inline-block;
    position: relative;
    width: 56px;
    height: 56px;
}

.button-position {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
}

.countBottomFavorite {
    right: 10px;
    bottom: 15px;
    position: absolute;
    display: block;
    padding: 4px 8px;
    font-size: 12px;
    text-align: center;
    z-index: 2;
}

.iconFavorite {
    top: 1px;
    right: 10px;
    position: absolute;
    display: block;
    padding: 4px 8px;
    font-size: 15px;
    text-align: center;
    z-index: 2;
}

.ul-Favorites {
    border: 1px solid #DBDBD3;
    font-size: 15px;
    background-color: #f5f5f2;
    padding: 0;
    min-height: 50px;
}

.li-Favorites {
    color: #66665D;
    list-style-type: none;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #DBDBD3;
}

.leftButton{
    bottom: 68px;
    right: 10px;
}

.rightButton {
    bottom: 68px;
    left: 37px;
}

.countBottom {
    right: 10px;
    top: 102px;
    position: absolute;
    display: block;
    padding: 4px 8px;
    font-size: 12px;
    text-align: center;
    z-index: 2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.iconOpMenu {
    max-width: 33%;
    height: 33%;
    /*position: absolute;*/
    /*top: 67%;*/
    left: 4%;
    -webkit-transform: translateY(150%) translateX(10%);
    transform: translateY(150%) translateX(10%);
    font-size: 50px;
    line-height: 50px;
    z-index: 2;
}
.tittleOpMenu {
    height: 32px;
    position: absolute;
    top: 26px;
    font-size: 11px;
    right: 9px;
    line-height: 13px;
    word-break: break-word;
    padding: 3px 1px;
    width: 90%;
    font-weight: 600;
}

