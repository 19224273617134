@font-face {
  font-family: 'metro';
  src: url('../fonts/metro.eot');
  src: url('../fonts/metro.eot') format('embedded-opentype'), url('../fonts/metro.woff') format('woff'), url('../fonts/metro.ttf') format('truetype'), url('../fonts/metro.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="mif-"],
[class*=" mif-"] {
  display: inline-block;
  font: normal normal normal 1.2em/1 metro;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}
.mif-lg {
  font-size: 1.3rem;
  line-height: 0.75em;
  vertical-align: -35%;
}
.mif-2x {
  font-size: 1.75rem;
  vertical-align: -25%;
}
.mif-3x {
  font-size: 2.625rem;
  vertical-align: -30%;
}
.mif-4x {
  font-size: 3.5rem;
  vertical-align: -35%;
}
/* transform functions */
/* Block function */
.ani-spin,
.ani-hover-spin:hover {
  animation: ani-spin 1.5s linear infinite;
}
.ani-spin.ani-fast,
.ani-hover-spin.ani-fast:hover {
  animation: ani-spin 0.7s linear infinite;
}
.ani-spin.ani-slow,
.ani-hover-spin.ani-slow:hover {
  animation: ani-spin 2.2s linear infinite;
}
.ani-pulse,
.ani-hover-pulse:hover {
  animation: ani-pulse 1.7s infinite;
}
.ani-pulse.ani-fast,
.ani-hover-pulse.ani-fast:hover {
  animation: ani-pulse 1s infinite;
}
.ani-pulse.ani-slow,
.ani-hover-pulse.ani-slow:hover {
  animation: ani-pulse 3s infinite;
}
.ani-spanner,
.ani-hover-spanner:hover {
  transform-origin-x: 90%;
  transform-origin-y: 35%;
  transform-origin-z: initial;
  animation: ani-wrench 2.5s ease infinite;
}
.ani-spanner.ani-fast,
.ani-hover-spanner.ani-fast:hover {
  animation: ani-wrench 1.2s ease infinite;
}
.ani-spanner.ani-slow,
.ani-hover-spanner.ani-slow:hover {
  animation: ani-wrench 3.7s ease infinite;
}
.ani-ring,
.ani-hover-ring:hover {
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
  animation: ani-ring 2s ease infinite;
}
.ani-ring.ani-fast,
.ani-hover-ring.ani-fast:hover {
  animation: ani-ring 1s ease infinite;
}
.ani-ring.ani-slow,
.ani-hover-ring.ani-slow:hover {
  animation: ani-ring 3s ease infinite;
}
.ani-vertical,
.ani-hover-vertical:hover {
  animation: ani-vertical 2s ease infinite;
}
.ani-vertical.ani-fast,
.ani-vertical.ani-fast:hover {
  animation: ani-vertical 1s ease infinite;
}
.ani-vertical.ani-slow,
.ani-hover-vertical.ani-slow:hover {
  animation: ani-vertical 4s ease infinite;
}
.ani-horizontal,
.ani-hover-horizontal:hover {
  animation: ani-horizontal 2s ease infinite;
}
.ani-horizontal.ani-fast,
.ani-horizontal.ani-fast:hover {
  animation: ani-horizontal 1s ease infinite;
}
.ani-horizontal.ani-slow,
.ani-horizontal.ani-slow:hover {
  animation: ani-horizontal 3s ease infinite;
}
.ani-flash,
.ani-hover-flash:hover {
  animation: ani-flash 2s ease infinite;
}
.ani-flash.ani-fast,
.ani-hover-flash.ani-fast:hover {
  animation: ani-flash 1s ease infinite;
}
.ani-flash.ani-slow,
.ani-hover-flash.ani-slow:hover {
  animation: ani-flash 3s ease infinite;
}
.ani-bounce,
.ani-hover-bounce:hover {
  animation: ani-bounce 2s ease infinite;
}
.ani-bounce.ani-fast,
.ani-hover-bounce.ani-fast:hover {
  animation: ani-bounce 1s ease infinite;
}
.ani-bounce.ani-slow,
.ani-hover-bounce.ani-slow:hover {
  animation: ani-bounce 3s ease infinite;
}
.ani-float,
.ani-hover-float:hover {
  animation: ani-float 2s linear infinite;
}
.ani-float.ani-fast,
.ani-hover-float.ani-fast:hover {
  animation: ani-float 1s linear infinite;
}
.ani-float.ani-slow,
.ani-hover-float.ani-slow:hover {
  animation: ani-float 3s linear infinite;
}
.ani-heartbeat,
.ani-hover-heartbeat:hover {
  animation: ani-heartbeat 2s linear infinite;
}
.ani-heartbeat.ani-fast,
.ani-hover-heartbeat.ani-fast:hover {
  animation: ani-heartbeat 1s linear infinite;
}
.ani-heartbeat.ani-slow,
.ani-hover-heartbeat.ani-slow:hover {
  animation: ani-heartbeat 3s linear infinite;
}
.ani-shake,
.ani-hover-shake:hover {
  animation: ani-wrench 2.5s ease infinite;
}
.ani-shake.ani-fast,
.ani-hover-shake.ani-fast:hover {
  animation: ani-wrench 1.2s ease infinite;
}
.ani-shake.ani-slow,
.ani-hover-shake.ani-slow:hover {
  animation: ani-wrench 3.7s ease infinite;
}
.ani-shuttle,
.ani-hover-shuttle:hover {
  animation: ani-shuttle 2s linear infinite;
}
.ani-shuttle.ani-fast,
.ani-hover-shuttle.ani-fast:hover {
  animation: ani-shuttle 1s linear infinite;
}
.ani-shuttle.ani-slow,
.ani-hover-shuttle.ani-slow:hover {
  animation: ani-shuttle 3s linear infinite;
}
.ani-pass,
.ani-hover-pass:hover {
  animation: ani-pass 2s linear infinite;
}
.ani-pass.ani-fast,
.ani-hover-pass.ani-fast:hover {
  animation: ani-pass 1s linear infinite;
}
.ani-pass.ani-slow,
.ani-hover-pass.ani-slow:hover {
  animation: ani-pass 3s linear infinite;
}
.ani-ripple,
.ani-hover-ripple:hover {
  animation: ani-ripple 2s infinite linear;
}
.ani-ripple.ani-fast,
.ani-hover-ripple.ani-fast:hover {
  animation: ani-ripple 1s infinite linear;
}
.ani-ripple.ani-slow,
.ani-hover-ripple.ani-slow:hover {
  animation: ani-ripple 3s infinite linear;
}
@keyframes swinging {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(10deg);
  }
  10% {
    transform: rotate(-9deg);
  }
  15% {
    transform: rotate(8deg);
  }
  20% {
    transform: rotate(-7deg);
  }
  25% {
    transform: rotate(6deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  35% {
    transform: rotate(4deg);
  }
  40% {
    transform: rotate(-3deg);
  }
  45% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes cubemove {
  25% {
    transform: translateX(10px) rotate(-90deg);
  }
  50% {
    transform: translateX(10px) translateY(10px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(10px) translateY(10px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(10px) rotate(-270deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes orbit {
  0% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: rotate(225deg);
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  35% {
    transform: rotate(495deg);
    animation-timing-function: ease-in-out;
  }
  42% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    opacity: 1;
    transform: rotate(835deg);
    animation-timing-function: linear;
  }
  76% {
    opacity: 1;
  }
  77% {
    transform: rotate(955deg);
    animation-timing-function: ease-in;
  }
  78% {
    transform: rotate(955deg);
    opacity: 0;
  }
  100% {
    transform: rotate(955deg);
    opacity: 0;
  }
}
@keyframes metro-slide {
  0% {
    left: -50%;
  }
  100% {
    left: 150%;
  }
}
@keyframes metro-opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes ani-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes ani-pulse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes ani-wrench {
  0% {
    transform: rotate(-12deg);
  }
  8% {
    transform: rotate(12deg);
  }
  10% {
    transform: rotate(24deg);
  }
  18% {
    transform: rotate(-24deg);
  }
  20% {
    transform: rotate(-24deg);
  }
  28% {
    transform: rotate(24deg);
  }
  30% {
    transform: rotate(24deg);
  }
  38% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(-24deg);
  }
  48% {
    transform: rotate(24deg);
  }
  50% {
    transform: rotate(24deg);
  }
  58% {
    transform: rotate(-24deg);
  }
  60% {
    transform: rotate(-24deg);
  }
  68% {
    transform: rotate(24deg);
  }
  75% {
    transform: rotate(0deg);
  }
}
@keyframes ani-ring {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }
}
@keyframes ani-vertical {
  0% {
    transform: translate(0, -3px);
  }
  4% {
    transform: translate(0, 3px);
  }
  8% {
    transform: translate(0, -3px);
  }
  12% {
    transform: translate(0, 3px);
  }
  16% {
    transform: translate(0, -3px);
  }
  20% {
    transform: translate(0, 3px);
  }
  22% {
    transform: translate(0, 0);
  }
}
@keyframes ani-horizontal {
  0% {
    transform: translate(0, 0);
  }
  6% {
    transform: translate(5px, 0);
  }
  12% {
    transform: translate(0, 0);
  }
  18% {
    transform: translate(5px, 0);
  }
  24% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(5px, 0);
  }
  36% {
    transform: translate(0, 0);
  }
}
@keyframes ani-flash {
  0%,
  100%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes ani-bounce {
  0%,
  10%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes ani-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes ani-heartbeat {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes ani-shuttle {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-8deg);
  }
  30%,
  50%,
  70% {
    transform: scale(1.3) rotate(8deg);
  }
  40%,
  60% {
    transform: scale(1.3) rotate(-8deg);
  }
  80% {
    transform: scale(1) rotate(0);
  }
}
@keyframes ani-pass {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}
@keyframes ani-ripple {
  0% {
    opacity: .6;
  }
  50% {
    transform: scale(1.8);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ani-shrink {
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes ani-drop {
  0% {
    transform: translateY(-50px);
  }
  25% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes ani-drop2 {
  0% {
    transform: translateY(-50px);
  }
  50% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes ani-drop3 {
  0% {
    transform: translateY(-50px);
  }
  75% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes ani-pre-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ani-bg-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.mif-ani-spin,
.mif-ani-hover-spin:hover {
  animation: ani-spin 1.5s linear infinite;
}
.mif-ani-spin.mif-ani-fast,
.mif-ani-hover-spin.mif-ani-fast:hover {
  animation: ani-spin 0.7s linear infinite;
}
.mif-ani-spin.mif-ani-slow,
.mif-ani-hover-spin.mif-ani-slow:hover {
  animation: ani-spin 2.2s linear infinite;
}
.mif-ani-pulse,
.mif-ani-hover-pulse:hover {
  animation: ani-pulse 1.7s infinite;
}
.mif-ani-pulse.mif-ani-fast,
.mif-ani-hover-pulse.mif-ani-fast:hover {
  animation: ani-pulse 1s infinite;
}
.mif-ani-pulse.mif-ani-slow,
.mif-ani-hover-pulse.mif-ani-slow:hover {
  animation: ani-pulse 3s infinite;
}
.mif-ani-spanner,
.mif-ani-hover-spanner:hover {
  transform-origin-x: 90%;
  transform-origin-y: 35%;
  transform-origin-z: initial;
  animation: ani-wrench 2.5s ease infinite;
}
.mif-ani-spanner.mif-ani-fast,
.mif-ani-hover-spanner.mif-ani-fast:hover {
  animation: ani-wrench 1.2s ease infinite;
}
.mif-ani-spanner.mif-ani-slow,
.mif-ani-hover-spanner.mif-ani-slow:hover {
  animation: ani-wrench 3.7s ease infinite;
}
.mif-ani-ring,
.mif-ani-hover-ring:hover {
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
  animation: ani-ring 2s ease infinite;
}
.mif-ani-ring.mif-ani-fast,
.mif-ani-hover-ring.mif-ani-fast:hover {
  animation: ani-ring 1s ease infinite;
}
.mif-ani-ring.mif-ani-slow,
.mif-ani-hover-ring.mif-ani-slow:hover {
  animation: ani-ring 3s ease infinite;
}
.mif-ani-vertical,
.mif-ani-hover-vertical:hover {
  animation: ani-vertical 2s ease infinite;
}
.mif-ani-vertical.mif-ani-fast,
.mif-ani-vertical.mif-ani-fast:hover {
  animation: ani-vertical 1s ease infinite;
}
.mif-ani-vertical.mif-ani-slow,
.mif-ani-hover-vertical.mif-ani-slow:hover {
  animation: ani-vertical 4s ease infinite;
}
.mif-ani-horizontal,
.mif-ani-hover-horizontal:hover {
  animation: ani-horizontal 2s ease infinite;
}
.mif-ani-horizontal.mif-ani-fast,
.mif-ani-horizontal.mif-ani-fast:hover {
  animation: ani-horizontal 1s ease infinite;
}
.mif-ani-horizontal.mif-ani-slow,
.mif-ani-horizontal.mif-ani-slow:hover {
  animation: ani-horizontal 3s ease infinite;
}
.mif-ani-flash,
.mif-ani-hover-flash:hover {
  animation: ani-flash 2s ease infinite;
}
.mif-ani-flash.mif-ani-fast,
.mif-ani-hover-flash.mif-ani-fast:hover {
  animation: ani-flash 1s ease infinite;
}
.mif-ani-flash.mif-ani-slow,
.mif-ani-hover-flash.mif-ani-slow:hover {
  animation: ani-flash 3s ease infinite;
}
.mif-ani-bounce,
.mif-ani-hover-bounce:hover {
  animation: ani-bounce 2s ease infinite;
}
.mif-ani-bounce.mif-ani-fast,
.mif-ani-hover-bounce.mif-ani-fast:hover {
  animation: ani-bounce 1s ease infinite;
}
.mif-ani-bounce.mif-ani-slow,
.mif-ani-hover-bounce.mif-ani-slow:hover {
  animation: ani-bounce 3s ease infinite;
}
.mif-ani-float,
.mif-ani-hover-float:hover {
  animation: ani-float 2s linear infinite;
}
.mif-ani-float.mif-ani-fast,
.mif-ani-hover-float.mif-ani-fast:hover {
  animation: ani-float 1s linear infinite;
}
.mif-ani-float.mif-ani-slow,
.mif-ani-hover-float.mif-ani-slow:hover {
  animation: ani-float 3s linear infinite;
}
.mif-ani-heartbeat,
.mif-ani-hover-heartbeat:hover {
  animation: ani-heartbeat 2s linear infinite;
}
.mif-ani-heartbeat.mif-ani-fast,
.mif-ani-hover-heartbeat.mif-ani-fast:hover {
  animation: ani-heartbeat 1s linear infinite;
}
.mif-ani-heartbeat.mif-ani-slow,
.mif-ani-hover-heartbeat.mif-ani-slow:hover {
  animation: ani-heartbeat 3s linear infinite;
}
.mif-ani-shake,
.mif-ani-hover-shake:hover {
  animation: ani-wrench 2.5s ease infinite;
}
.mif-ani-shake.mif-ani-fast,
.mif-ani-hover-shake.mif-ani-fast:hover {
  animation: ani-wrench 1.2s ease infinite;
}
.mif-ani-shake.mif-ani-slow,
.mif-ani-hover-shake.mif-ani-slow:hover {
  animation: ani-wrench 3.7s ease infinite;
}
.mif-ani-shuttle,
.mif-ani-hover-shuttle:hover {
  animation: ani-shuttle 2s linear infinite;
}
.mif-ani-shuttle.mif-ani-fast,
.mif-ani-hover-shuttle.mif-ani-fast:hover {
  animation: ani-shuttle 1s linear infinite;
}
.mif-ani-shuttle.mif-ani-slow,
.mif-ani-hover-shuttle.mif-ani-slow:hover {
  animation: ani-shuttle 3s linear infinite;
}
.mif-ani-pass,
.mif-ani-hover-pass:hover {
  animation: ani-pass 2s linear infinite;
}
.mif-ani-pass.mif-ani-fast,
.mif-ani-hover-pass.mif-ani-fast:hover {
  animation: ani-pass 1s linear infinite;
}
.mif-ani-pass.mif-ani-slow,
.mif-ani-hover-pass.mif-ani-slow:hover {
  animation: ani-pass 3s linear infinite;
}
.mif-ani-ripple,
.mif-ani-hover-ripple:hover {
  animation: ani-ripple 2s infinite linear;
}
.mif-ani-ripple.mif-ani-fast,
.mif-ani-hover-ripple.mif-ani-fast:hover {
  animation: ani-ripple 1s infinite linear;
}
.mif-ani-ripple.mif-ani-slow,
.mif-ani-hover-ripple.mif-ani-slow:hover {
  animation: ani-ripple 3s infinite linear;
}
.mif-unlink:before {
  content: "\f127";
}
.mif-fire-extinguisher:before {
  content: "\f134";
}
.mif-eur:before {
  content: "\f153";
}
.mif-gbp:before {
  content: "\f154";
}
.mif-dollar2:before {
  content: "\f155";
}
.mif-inr:before {
  content: "\f156";
}
.mif-cny:before {
  content: "\f157";
}
.mif-rouble:before {
  content: "\f158";
}
.mif-krw:before {
  content: "\f159";
}
.mif-bitcoin:before {
  content: "\f15a";
}
.mif-youtube2:before {
  content: "\f167";
}
.mif-youtube-play:before {
  content: "\f16a";
}
.mif-linux:before {
  content: "\f17c";
}
.mif-try:before {
  content: "\f195";
}
.mif-space-shuttle:before {
  content: "\f197";
}
.mif-openid:before {
  content: "\f19b";
}
.mif-digg:before {
  content: "\f1a6";
}
.mif-language:before {
  content: "\f1ab";
}
.mif-automobile:before {
  content: "\f1b9";
}
.mif-cab:before {
  content: "\f1ba";
}
.mif-jsfiddle:before {
  content: "\f1cc";
}
.mif-google-wallet:before {
  content: "\f1ee";
}
.mif-copyright:before {
  content: "\f1f9";
}
.mif-bicycle:before {
  content: "\f206";
}
.mif-bus:before {
  content: "\f207";
}
.mif-ship:before {
  content: "\f21a";
}
.mif-motorcycle:before {
  content: "\f21c";
}
.mif-train:before {
  content: "\f238";
}
.mif-subway:before {
  content: "\f239";
}
.mif-opencart:before {
  content: "\f23d";
}
.mif-trademark:before {
  content: "\f25c";
}
.mif-registered:before {
  content: "\f25d";
}
.mif-creative-commons:before {
  content: "\f25e";
}
.mif-wikipedia:before {
  content: "\f266";
}
.mif-amazon:before {
  content: "\f270";
}
.mif-fonticons:before {
  content: "\f280";
}
.mif-user-md:before {
  content: "\f0f0";
}
.mif-stethoscope:before {
  content: "\f0f1";
}
.mif-ambulance:before {
  content: "\f0f9";
}
.mif-medkit:before {
  content: "\f0fa";
}
.mif-paw:before {
  content: "\f1b0";
}
.mif-file-pdf:before {
  content: "\f1c1";
}
.mif-file-word:before {
  content: "\f1c2";
}
.mif-file-excel:before {
  content: "\f1c3";
}
.mif-file-powerpoint:before {
  content: "\f1c4";
}
.mif-file-image:before {
  content: "\f1c5";
}
.mif-file-archive:before {
  content: "\f1c6";
}
.mif-file-audio:before {
  content: "\f1c7";
}
.mif-file-movie:before {
  content: "\f1c8";
}
.mif-file-code:before {
  content: "\f1c9";
}
.mif-visa:before {
  content: "\f1f0";
}
.mif-mastercard:before {
  content: "\f1f1";
}
.mif-discover:before {
  content: "\f1f2";
}
.mif-amex:before {
  content: "\f1f3";
}
.mif-cc-paypal:before {
  content: "\f1f4";
}
.mif-heartbeat:before {
  content: "\f21e";
}
.mif-venus:before {
  content: "\f221";
}
.mif-mars:before {
  content: "\f222";
}
.mif-medium:before {
  content: "\f23a";
}
.mif-earth2:before {
  content: "\e6c1";
}
.mif-shit:before {
  content: "\e6c2";
}
.mif-broadcast:before {
  content: "\f048";
}
.mif-organization:before {
  content: "\f037";
}
.mif-squirrel:before {
  content: "\f0b2";
}
.mif-steps:before {
  content: "\f0c7";
}
.mif-versions:before {
  content: "\f064";
}
.mif-microscope:before {
  content: "\f089";
}
.mif-library:before {
  content: "\e921";
}
.mif-file-binary:before {
  content: "\f094";
}
.mif-mail-read:before {
  content: "\f03c";
}
.mif-quote:before {
  content: "\f063";
}
.mif-sunrise:before {
  content: "\e66c";
}
.mif-sun:before {
  content: "\e66d";
}
.mif-moon:before {
  content: "\e66e";
}
.mif-sun3:before {
  content: "\e66f";
}
.mif-windy:before {
  content: "\e670";
}
.mif-wind:before {
  content: "\e671";
}
.mif-snowflake:before {
  content: "\e672";
}
.mif-cloudy:before {
  content: "\e673";
}
.mif-cloud2:before {
  content: "\e674";
}
.mif-weather:before {
  content: "\e675";
}
.mif-weather2:before {
  content: "\e676";
}
.mif-weather3:before {
  content: "\e677";
}
.mif-lines:before {
  content: "\e678";
}
.mif-cloud3:before {
  content: "\e679";
}
.mif-lightning:before {
  content: "\e67a";
}
.mif-lightning2:before {
  content: "\e67b";
}
.mif-rainy:before {
  content: "\e67c";
}
.mif-rainy2:before {
  content: "\e67d";
}
.mif-windy2:before {
  content: "\e67e";
}
.mif-windy3:before {
  content: "\e67f";
}
.mif-snowy:before {
  content: "\e680";
}
.mif-snowy2:before {
  content: "\e681";
}
.mif-snowy3:before {
  content: "\e682";
}
.mif-weather4:before {
  content: "\e683";
}
.mif-cloudy2:before {
  content: "\e684";
}
.mif-cloud4:before {
  content: "\e685";
}
.mif-lightning3:before {
  content: "\e686";
}
.mif-sun4:before {
  content: "\e687";
}
.mif-moon2:before {
  content: "\e688";
}
.mif-cloudy3:before {
  content: "\e689";
}
.mif-cloud5:before {
  content: "\e68a";
}
.mif-cloud6:before {
  content: "\e68b";
}
.mif-lightning4:before {
  content: "\e68c";
}
.mif-rainy3:before {
  content: "\e68d";
}
.mif-rainy4:before {
  content: "\e68e";
}
.mif-windy4:before {
  content: "\e68f";
}
.mif-windy5:before {
  content: "\e690";
}
.mif-snowy4:before {
  content: "\e691";
}
.mif-snowy5:before {
  content: "\e692";
}
.mif-weather5:before {
  content: "\e693";
}
.mif-cloudy4:before {
  content: "\e694";
}
.mif-lightning5:before {
  content: "\e695";
}
.mif-thermometer:before {
  content: "\e696";
}
.mif-none:before {
  content: "\e698";
}
.mif-celsius:before {
  content: "\e699";
}
.mif-fahrenheit:before {
  content: "\e69a";
}
.mif-home:before {
  content: "\e900";
}
.mif-pencil:before {
  content: "\e905";
}
.mif-eyedropper:before {
  content: "\e90a";
}
.mif-paint:before {
  content: "\e90c";
}
.mif-image:before {
  content: "\e90d";
}
.mif-images:before {
  content: "\e90e";
}
.mif-camera:before {
  content: "\e90f";
}
.mif-headphones:before {
  content: "\e910";
}
.mif-music:before {
  content: "\e911";
}
.mif-film:before {
  content: "\e913";
}
.mif-video-camera:before {
  content: "\e914";
}
.mif-dice:before {
  content: "\e915";
}
.mif-wifi-connect:before {
  content: "\e91b";
}
.mif-feed:before {
  content: "\e91d";
}
.mif-mic:before {
  content: "\e91e";
}
.mif-books:before {
  content: "\e920";
}
.mif-file-empty:before {
  content: "\e924";
}
.mif-files-empty:before {
  content: "\e925";
}
.mif-file-text:before {
  content: "\e926";
}
.mif-file-picture:before {
  content: "\e927";
}
.mif-file-music:before {
  content: "\e928";
}
.mif-file-play:before {
  content: "\e929";
}
.mif-file-video:before {
  content: "\e92a";
}
.mif-file-zip:before {
  content: "\e92b";
}
.mif-stack:before {
  content: "\e92e";
}
.mif-folder:before {
  content: "\e92f";
}
.mif-folder-open:before {
  content: "\e930";
}
.mif-folder-plus:before {
  content: "\e931";
}
.mif-folder-minus:before {
  content: "\e932";
}
.mif-folder-download:before {
  content: "\e933";
}
.mif-folder-upload:before {
  content: "\e934";
}
.mif-tag:before {
  content: "\e935";
}
.mif-tags:before {
  content: "\e936";
}
.mif-barcode:before {
  content: "\e937";
}
.mif-qrcode:before {
  content: "\e938";
}
.mif-cart:before {
  content: "\e93a";
}
.mif-credit-card:before {
  content: "\e93f";
}
.mif-calculator:before {
  content: "\e940";
}
.mif-help:before {
  content: "\e941";
}
.mif-phone:before {
  content: "\e942";
}
.mif-envelop:before {
  content: "\e945";
}
.mif-location:before {
  content: "\e948";
}
.mif-compass:before {
  content: "\e949";
}
.mif-compass2:before {
  content: "\e94a";
}
.mif-map:before {
  content: "\e94b";
}
.mif-history:before {
  content: "\e94d";
}
.mif-bell:before {
  content: "\e951";
}
.mif-calendar:before {
  content: "\e953";
}
.mif-printer:before {
  content: "\e954";
}
.mif-keyboard:before {
  content: "\e955";
}
.mif-display:before {
  content: "\e956";
}
.mif-laptop:before {
  content: "\e957";
}
.mif-mobile:before {
  content: "\e959";
}
.mif-tablet:before {
  content: "\e95a";
}
.mif-download:before {
  content: "\e960";
}
.mif-upload:before {
  content: "\e961";
}
.mif-floppy-disk:before {
  content: "\e962";
}
.mif-drive:before {
  content: "\e963";
}
.mif-database:before {
  content: "\e964";
}
.mif-undo:before {
  content: "\e965";
}
.mif-redo:before {
  content: "\e966";
}
.mif-bubble:before {
  content: "\e96b";
}
.mif-bubbles:before {
  content: "\e96c";
}
.mif-user:before {
  content: "\e971";
}
.mif-users:before {
  content: "\e972";
}
.mif-user-plus:before {
  content: "\e973";
}
.mif-user-minus:before {
  content: "\e974";
}
.mif-user-check:before {
  content: "\e975";
}
.mif-hour-glass:before {
  content: "\e979";
}
.mif-spinner:before {
  content: "\e97a";
}
.mif-spinner1:before {
  content: "\e97b";
}
.mif-spinner2:before {
  content: "\e97d";
}
.mif-spinner3:before {
  content: "\e981";
}
.mif-spinner4:before {
  content: "\e982";
}
.mif-spinner5:before {
  content: "\e983";
}
.mif-search:before {
  content: "\e986";
}
.mif-zoom-in:before {
  content: "\e987";
}
.mif-zoom-out:before {
  content: "\e988";
}
.mif-enlarge:before {
  content: "\e989";
}
.mif-shrink:before {
  content: "\e98a";
}
.mif-enlarge2:before {
  content: "\e98b";
}
.mif-shrink2:before {
  content: "\e98c";
}
.mif-key:before {
  content: "\e98d";
}
.mif-wrench:before {
  content: "\e991";
}
.mif-equalizer:before {
  content: "\e992";
}
.mif-equalizer-v:before {
  content: "\e993";
}
.mif-cog:before {
  content: "\e994";
}
.mif-cogs:before {
  content: "\e995";
}
.mif-magic-wand:before {
  content: "\e997";
}
.mif-bug:before {
  content: "\e999";
}
.mif-chart-pie:before {
  content: "\e99a";
}
.mif-chart-dots:before {
  content: "\e99b";
}
.mif-chart-bars:before {
  content: "\e99c";
}
.mif-chart-bars2:before {
  content: "\e99d";
}
.mif-trophy:before {
  content: "\e99e";
}
.mif-gift:before {
  content: "\e99f";
}
.mif-spoon-fork:before {
  content: "\e9a3";
}
.mif-rocket:before {
  content: "\e9a5";
}
.mif-meter:before {
  content: "\e9a6";
}
.mif-hammer:before {
  content: "\e9a8";
}
.mif-fire:before {
  content: "\e9a9";
}
.mif-lab:before {
  content: "\e9aa";
}
.mif-bin:before {
  content: "\e9ac";
}
.mif-truck:before {
  content: "\e9b0";
}
.mif-target:before {
  content: "\e9b3";
}
.mif-power:before {
  content: "\e9b5";
}
.mif-switch:before {
  content: "\e9b6";
}
.mif-power-cord:before {
  content: "\e9b7";
}
.mif-clipboard:before {
  content: "\e9b8";
}
.mif-list-numbered:before {
  content: "\e9b9";
}
.mif-list:before {
  content: "\e9ba";
}
.mif-list2:before {
  content: "\e9bb";
}
.mif-tree:before {
  content: "\e9bc";
}
.mif-cloud:before {
  content: "\e9c1";
}
.mif-cloud-download:before {
  content: "\e9c2";
}
.mif-cloud-upload:before {
  content: "\e9c3";
}
.mif-download2:before {
  content: "\e9c7";
}
.mif-upload2:before {
  content: "\e9c8";
}
.mif-earth:before {
  content: "\e9ca";
}
.mif-link:before {
  content: "\e9cb";
}
.mif-flag:before {
  content: "\e9cc";
}
.mif-attachment:before {
  content: "\e9cd";
}
.mif-eye:before {
  content: "\e9ce";
}
.mif-bookmark:before {
  content: "\e9d2";
}
.mif-bookmarks:before {
  content: "\e9d3";
}
.mif-contrast:before {
  content: "\e9d5";
}
.mif-brightness:before {
  content: "\e9d6";
}
.mif-star-empty:before {
  content: "\e9d7";
}
.mif-star-half:before {
  content: "\e9d8";
}
.mif-star-full:before {
  content: "\e9d9";
}
.mif-heart:before {
  content: "\e9da";
}
.mif-heart-broken:before {
  content: "\e9db";
}
.mif-warning:before {
  content: "\ea07";
}
.mif-notification:before {
  content: "\ea08";
}
.mif-question:before {
  content: "\ea09";
}
.mif-plus:before {
  content: "\ea0a";
}
.mif-minus:before {
  content: "\ea0b";
}
.mif-info:before {
  content: "\ea0c";
}
.mif-cancel:before {
  content: "\ea0d";
}
.mif-blocked:before {
  content: "\ea0e";
}
.mif-cross:before {
  content: "\ea0f";
}
.mif-checkmark:before {
  content: "\ea10";
}
.mif-spell-check:before {
  content: "\ea12";
}
.mif-enter:before {
  content: "\ea13";
}
.mif-exit:before {
  content: "\ea14";
}
.mif-play:before {
  content: "\ea1c";
}
.mif-pause:before {
  content: "\ea1d";
}
.mif-stop:before {
  content: "\ea1e";
}
.mif-backward:before {
  content: "\ea1f";
}
.mif-forward:before {
  content: "\ea20";
}
.mif-first:before {
  content: "\ea21";
}
.mif-last:before {
  content: "\ea22";
}
.mif-previous:before {
  content: "\ea23";
}
.mif-next:before {
  content: "\ea24";
}
.mif-eject:before {
  content: "\ea25";
}
.mif-volume-high:before {
  content: "\ea26";
}
.mif-volume-medium:before {
  content: "\ea27";
}
.mif-volume-low:before {
  content: "\ea28";
}
.mif-volume-mute:before {
  content: "\ea29";
}
.mif-volume-mute2:before {
  content: "\ea2a";
}
.mif-volume-plus:before {
  content: "\ea2b";
}
.mif-volume-minus:before {
  content: "\ea2c";
}
.mif-loop:before {
  content: "\ea2d";
}
.mif-loop2:before {
  content: "\ea2e";
}
.mif-infinite:before {
  content: "\ea2f";
}
.mif-shuffle:before {
  content: "\ea30";
}
.mif-arrow-up-left:before {
  content: "\ea39";
}
.mif-arrow-up:before {
  content: "\ea3a";
}
.mif-arrow-up-right:before {
  content: "\ea3b";
}
.mif-arrow-right:before {
  content: "\ea3c";
}
.mif-arrow-down-right:before {
  content: "\ea3d";
}
.mif-arrow-down:before {
  content: "\ea3e";
}
.mif-arrow-down-left:before {
  content: "\ea3f";
}
.mif-arrow-left:before {
  content: "\ea40";
}
.mif-tab:before {
  content: "\ea45";
}
.mif-move-up:before {
  content: "\ea46";
}
.mif-move-down:before {
  content: "\ea47";
}
.mif-sort-asc:before {
  content: "\ea4c";
}
.mif-sort-desc:before {
  content: "\ea4d";
}
.mif-command:before {
  content: "\ea4e";
}
.mif-shift:before {
  content: "\ea4f";
}
.mif-crop:before {
  content: "\ea57";
}
.mif-filter:before {
  content: "\ea5b";
}
.mif-bold:before {
  content: "\ea62";
}
.mif-underline:before {
  content: "\ea63";
}
.mif-italic:before {
  content: "\ea64";
}
.mif-strikethrough:before {
  content: "\ea65";
}
.mif-page-break:before {
  content: "\ea68";
}
.mif-superscript:before {
  content: "\ea69";
}
.mif-subscript:before {
  content: "\ea6a";
}
.mif-table:before {
  content: "\ea71";
}
.mif-insert-template:before {
  content: "\ea72";
}
.mif-pilcrow:before {
  content: "\ea73";
}
.mif-ltr:before {
  content: "\ea74";
}
.mif-rtl:before {
  content: "\ea75";
}
.mif-section:before {
  content: "\ea76";
}
.mif-paragraph-left:before {
  content: "\ea77";
}
.mif-paragraph-center:before {
  content: "\ea78";
}
.mif-paragraph-right:before {
  content: "\ea79";
}
.mif-paragraph-justify:before {
  content: "\ea7a";
}
.mif-indent-increase:before {
  content: "\ea7b";
}
.mif-indent-decrease:before {
  content: "\ea7c";
}
.mif-embed:before {
  content: "\ea7f";
}
.mif-embed2:before {
  content: "\ea80";
}
.mif-share:before {
  content: "\ea82";
}
.mif-google:before {
  content: "\ea87";
}
.mif-google-plus:before {
  content: "\ea88";
}
.mif-facebook:before {
  content: "\ea8d";
}
.mif-twitter:before {
  content: "\ea91";
}
.mif-feed3:before {
  content: "\ea95";
}
.mif-youtube:before {
  content: "\ea99";
}
.mif-steam:before {
  content: "\eaae";
}
.mif-onedrive:before {
  content: "\eab0";
}
.mif-github:before {
  content: "\eab3";
}
.mif-git:before {
  content: "\eab5";
}
.mif-apple:before {
  content: "\eabf";
}
.mif-android:before {
  content: "\eac1";
}
.mif-windows:before {
  content: "\eac3";
}
.mif-skype:before {
  content: "\eac6";
}
.mif-linkedin:before {
  content: "\eac8";
}
.mif-html5:before {
  content: "\eadf";
}
.mif-css3:before {
  content: "\eae1";
}
.mif-chrome:before {
  content: "\eae5";
}
.mif-firefox:before {
  content: "\eae6";
}
.mif-ie:before {
  content: "\eae7";
}
.mif-opera:before {
  content: "\eae8";
}
.mif-safari:before {
  content: "\eae9";
}
.mif-airplane:before {
  content: "\e6c3";
}
.mif-truck2:before {
  content: "\e6c4";
}
.mif-instagram:before {
  content: "\e6c5";
}
.mif-twitch:before {
  content: "\e6c6";
}
.mif-picassa:before {
  content: "\e6c7";
}
.mif-deviantart2:before {
  content: "\e6c8";
}
.mif-wordpress2:before {
  content: "\e6c9";
}
.mif-joomla:before {
  content: "\e6ca";
}
.mif-blogger:before {
  content: "\e6cb";
}
.mif-tux:before {
  content: "\e6cc";
}
.mif-finder:before {
  content: "\e6cd";
}
.mif-soundcloud:before {
  content: "\e6ce";
}
.mif-reddit:before {
  content: "\e6cf";
}
.mif-delicious:before {
  content: "\e6d0";
}
.mif-stackoverflow:before {
  content: "\e6d1";
}
.mif-flattr:before {
  content: "\e6d2";
}
.mif-foursquare:before {
  content: "\e6d3";
}
.mif-file-openoffice:before {
  content: "\e6d4";
}
.mif-libreoffice:before {
  content: "\e6d5";
}
.mif-codepen:before {
  content: "\e6d6";
}
.mif-IcoMoon:before {
  content: "\e6d7";
}
.mif-stack2:before {
  content: "\e6b9";
}
.mif-stack3:before {
  content: "\e6ba";
}
.mif-lamp:before {
  content: "\e6bb";
}
.mif-injection:before {
  content: "\e6bc";
}
.mif-thermometer2:before {
  content: "\e6bd";
}
.mif-justice:before {
  content: "\e6be";
}
.mif-cabinet:before {
  content: "\e62b";
}
.mif-suitcase:before {
  content: "\e62c";
}
.mif-gamepad:before {
  content: "\e65e";
}
.mif-satellite:before {
  content: "\e65f";
}
.mif-lock:before {
  content: "\e660";
}
.mif-unlock:before {
  content: "\e661";
}
.mif-battery-full:before {
  content: "\e62d";
}
.mif-battery-two:before {
  content: "\e62e";
}
.mif-battery-one:before {
  content: "\e62f";
}
.mif-battery-empty:before {
  content: "\e630";
}
.mif-battery-charge:before {
  content: "\e631";
}
.mif-tools:before {
  content: "\e632";
}
.mif-pin:before {
  content: "\e662";
}
.mif-discout:before {
  content: "\e663";
}
.mif-profile:before {
  content: "\e664";
}
.mif-dollar:before {
  content: "\e665";
}
.mif-dollars:before {
  content: "\e666";
}
.mif-coins:before {
  content: "\e6b8";
}
.mif-male:before {
  content: "\e667";
}
.mif-female:before {
  content: "\e668";
}
.mif-piano:before {
  content: "\e669";
}
.mif-anchor:before {
  content: "\e66a";
}
.mif-directions-bike:before {
  content: "\e6bf";
}
.mif-location-city:before {
  content: "\e6c0";
}
.mif-wifi-low:before {
  content: "\e60c";
}
.mif-wifi-mid:before {
  content: "\e60d";
}
.mif-wifi-full:before {
  content: "\e634";
}
.mif-tablet-landscape:before {
  content: "\e635";
}
.mif-calculator2:before {
  content: "\e636";
}
.mif-barbell:before {
  content: "\e637";
}
.mif-chart-line:before {
  content: "\e656";
}
.mif-3d-rotation:before {
  content: "\e600";
}
.mif-alarm:before {
  content: "\e601";
}
.mif-alarm-on:before {
  content: "\e602";
}
.mif-favorite:before {
  content: "\e603";
}
.mif-perm-phone-msg:before {
  content: "\e604";
}
.mif-print:before {
  content: "\e605";
}
.mif-bt-settings:before {
  content: "\e606";
}
.mif-settings-ethernet:before {
  content: "\e607";
}
.mif-settings-phone:before {
  content: "\e608";
}
.mif-settings-power:before {
  content: "\e609";
}
.mif-settings-voice:before {
  content: "\e60a";
}
.mif-shopping-basket:before {
  content: "\e60b";
}
.mif-dialer-sip:before {
  content: "\e60e";
}
.mif-dialpad:before {
  content: "\e60f";
}
.mif-contacts-dialer:before {
  content: "\e610";
}
.mif-contacts-mail:before {
  content: "\e611";
}
.mif-ring-volume:before {
  content: "\e612";
}
.mif-voicemail:before {
  content: "\e613";
}
.mif-drafts:before {
  content: "\e614";
}
.mif-mail:before {
  content: "\e615";
}
.mif-bluetooth:before {
  content: "\e626";
}
.mif-bt-connected:before {
  content: "\e627";
}
.mif-bt-disabled:before {
  content: "\e628";
}
.mif-bt-searching:before {
  content: "\e629";
}
.mif-brightness-auto:before {
  content: "\e62a";
}
.mif-multitrack-audio:before {
  content: "\e616";
}
.mif-widgets:before {
  content: "\e617";
}
.mif-usb:before {
  content: "\e638";
}
.mif-money:before {
  content: "\e639";
}
.mif-vertical-align-bottom:before {
  content: "\e63a";
}
.mif-vertical-align-center:before {
  content: "\e63b";
}
.mif-vertical-align-top:before {
  content: "\e63c";
}
.mif-file-download:before {
  content: "\e63d";
}
.mif-file-upload:before {
  content: "\e63e";
}
.mif-keyboard-return:before {
  content: "\e63f";
}
.mif-keyboard-voice:before {
  content: "\e640";
}
.mif-phonelink:before {
  content: "\e641";
}
.mif-phonelink-off:before {
  content: "\e642";
}
.mif-security:before {
  content: "\e618";
}
.mif-looks:before {
  content: "\e643";
}
.mif-palette:before {
  content: "\e619";
}
.mif-layers:before {
  content: "\e644";
}
.mif-layers-clear:before {
  content: "\e61a";
}
.mif-local-airport:before {
  content: "\e645";
}
.mif-florist:before {
  content: "\e61b";
}
.mif-gas-station:before {
  content: "\e61c";
}
.mif-hotel:before {
  content: "\e646";
}
.mif-local-service:before {
  content: "\e61d";
}
.mif-map2:before {
  content: "\e620";
}
.mif-my-location:before {
  content: "\e61e";
}
.mif-traff:before {
  content: "\e621";
}
.mif-apps:before {
  content: "\e647";
}
.mif-chevron-left:before {
  content: "\e648";
}
.mif-chevron-right:before {
  content: "\e649";
}
.mif-expand-less:before {
  content: "\e64a";
}
.mif-expand-more:before {
  content: "\e64b";
}
.mif-menu:before {
  content: "\e64c";
}
.mif-more-horiz:before {
  content: "\e64d";
}
.mif-more-vert:before {
  content: "\e64e";
}
.mif-unfold-less:before {
  content: "\e64f";
}
.mif-unfold-more:before {
  content: "\e650";
}
.mif-bt-audio:before {
  content: "\e651";
}
.mif-not:before {
  content: "\e633";
}
.mif-drive-eta:before {
  content: "\e652";
}
.mif-event-available:before {
  content: "\e653";
}
.mif-event-busy:before {
  content: "\e654";
}
.mif-folder-special:before {
  content: "\e655";
}
.mif-phone-bt:before {
  content: "\e657";
}
.mif-phone-forwarded:before {
  content: "\e658";
}
.mif-phone-in-talk:before {
  content: "\e659";
}
.mif-phone-locked:before {
  content: "\e65a";
}
.mif-phone-missed:before {
  content: "\e65b";
}
.mif-phone-paused:before {
  content: "\e65c";
}
.mif-sd-card:before {
  content: "\e65d";
}
.mif-sync-disabled:before {
  content: "\e622";
}
.mif-sync-problem:before {
  content: "\e623";
}
.mif-vpn-lock:before {
  content: "\e624";
}
.mif-vpn-publ:before {
  content: "\e625";
}
.mif-school:before {
  content: "\e61f";
}
.mif-chevron-thin-down:before {
  content: "\e66b";
}
.mif-chevron-thin-left:before {
  content: "\e697";
}
.mif-chevron-thin-right:before {
  content: "\e69b";
}
.mif-chevron-thin-up:before {
  content: "\e69c";
}
.mif-flow-branch:before {
  content: "\e69d";
}
.mif-flow-cascade:before {
  content: "\e69e";
}
.mif-flow-line:before {
  content: "\e69f";
}
.mif-flow-parallel:before {
  content: "\e6a0";
}
.mif-flow-tree:before {
  content: "\e6a1";
}
.mif-air:before {
  content: "\e6a2";
}
.mif-medal:before {
  content: "\e6a3";
}
.mif-paper-plane:before {
  content: "\e6a4";
}
.mif-shareable:before {
  content: "\e6a5";
}
.mif-shop:before {
  content: "\e6a6";
}
.mif-shopping-basket2:before {
  content: "\e6a7";
}
.mif-thumbs-down:before {
  content: "\e6a8";
}
.mif-thumbs-up:before {
  content: "\e6a9";
}
.mif-traffic-cone:before {
  content: "\e6aa";
}
.mif-water:before {
  content: "\e6ab";
}
.mif-creative-cloud:before {
  content: "\e6ac";
}
.mif-dropbox:before {
  content: "\e6ad";
}
.mif-evernote:before {
  content: "\e6ae";
}
.mif-paypal:before {
  content: "\e6af";
}
.mif-swarm:before {
  content: "\e6b0";
}
.mif-vk:before {
  content: "\e6b1";
}
.mif-yelp:before {
  content: "\e6b2";
}
.mif-dribbble:before {
  content: "\e6b3";
}
.mif-lastfm:before {
  content: "\e6b4";
}
.mif-pinterest:before {
  content: "\e6b5";
}
.mif-stumbleupon:before {
  content: "\e6b6";
}
.mif-vimeo:before {
  content: "\e6b7";
}
