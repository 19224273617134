.op-default {
  background-color: rgba(27, 161, 226, 0.7);
}
.bd-black {
  border-color: #000000 !important;
}
.ol-black {
  outline-color: #000000 !important;
}
.op-black {
  background-color: rgba(0, 0, 0, 0.7);
}
.ribbed-black {
  background: #000000 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-black:before {
  background: #000000 !important;
}
.before-fg-black:before {
  color: #000000 !important;
}
.after-bg-black:after {
  background: #000000 !important;
}
.after-fg-black:after {
  color: #000000 !important;
}
.bg-hover-black:hover {
  background: #000000 !important;
}
.bg-active-black:active {
  background: #000000 !important;
}
.bg-focus-black:focus {
  background: #000000 !important;
}
.fg-hover-black:hover {
  color: #000000 !important;
}
.fg-active-black:active {
  color: #000000 !important;
}
.fg-focus-black:focus {
  color: #000000 !important;
}
.bd-white {
  border-color: #ffffff !important;
}
.ol-white {
  outline-color: #ffffff !important;
}
.op-white {
  background-color: rgba(255, 255, 255, 0.7);
}
.ribbed-white {
  background: #ffffff linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-white:before {
  background: #ffffff !important;
}
.before-fg-white:before {
  color: #ffffff !important;
}
.after-bg-white:after {
  background: #ffffff !important;
}
.after-fg-white:after {
  color: #ffffff !important;
}
.bg-hover-white:hover {
  background: #ffffff !important;
}
.bg-active-white:active {
  background: #ffffff !important;
}
.bg-focus-white:focus {
  background: #ffffff !important;
}
.fg-hover-white:hover {
  color: #ffffff !important;
}
.fg-active-white:active {
  color: #ffffff !important;
}
.fg-focus-white:focus {
  color: #ffffff !important;
}
.bd-lime {
  border-color: #a4c400 !important;
}
.ol-lime {
  outline-color: #a4c400 !important;
}
.op-lime {
  background-color: rgba(164, 196, 0, 0.7);
}
.ribbed-lime {
  background: #a4c400 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lime:before {
  background: #a4c400 !important;
}
.before-fg-lime:before {
  color: #a4c400 !important;
}
.after-bg-lime:after {
  background: #a4c400 !important;
}
.after-fg-lime:after {
  color: #a4c400 !important;
}
.bg-hover-lime:hover {
  background: #a4c400 !important;
}
.bg-active-lime:active {
  background: #a4c400 !important;
}
.bg-focus-lime:focus {
  background: #a4c400 !important;
}
.fg-hover-lime:hover {
  color: #a4c400 !important;
}
.fg-active-lime:active {
  color: #a4c400 !important;
}
.fg-focus-lime:focus {
  color: #a4c400 !important;
}
.bd-green {
  border-color: #60a917 !important;
}
.ol-green {
  outline-color: #60a917 !important;
}
.op-green {
  background-color: rgba(96, 169, 23, 0.7);
}
.ribbed-green {
  background: #60a917 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-green:before {
  background: #60a917 !important;
}
.before-fg-green:before {
  color: #60a917 !important;
}
.after-bg-green:after {
  background: #60a917 !important;
}
.after-fg-green:after {
  color: #60a917 !important;
}
.bg-hover-green:hover {
  background: #60a917 !important;
}
.bg-active-green:active {
  background: #60a917 !important;
}
.bg-focus-green:focus {
  background: #60a917 !important;
}
.fg-hover-green:hover {
  color: #60a917 !important;
}
.fg-active-green:active {
  color: #60a917 !important;
}
.fg-focus-green:focus {
  color: #60a917 !important;
}
.bd-emerald {
  border-color: #008a00 !important;
}
.ol-emerald {
  outline-color: #008a00 !important;
}
.op-emerald {
  background-color: rgba(0, 138, 0, 0.7);
}
.ribbed-emerald {
  background: #008a00 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-emerald:before {
  background: #008a00 !important;
}
.before-fg-emerald:before {
  color: #008a00 !important;
}
.after-bg-emerald:after {
  background: #008a00 !important;
}
.after-fg-emerald:after {
  color: #008a00 !important;
}
.bg-hover-emerald:hover {
  background: #008a00 !important;
}
.bg-active-emerald:active {
  background: #008a00 !important;
}
.bg-focus-emerald:focus {
  background: #008a00 !important;
}
.fg-hover-emerald:hover {
  color: #008a00 !important;
}
.fg-active-emerald:active {
  color: #008a00 !important;
}
.fg-focus-emerald:focus {
  color: #008a00 !important;
}
.bd-blue {
  border-color: #00AFF0 !important;
}
.ol-blue {
  outline-color: #00AFF0 !important;
}
.op-blue {
  background-color: rgba(0, 175, 240, 0.7);
}
.ribbed-blue {
  background: #00AFF0 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-blue:before {
  background: #00AFF0 !important;
}
.before-fg-blue:before {
  color: #00AFF0 !important;
}
.after-bg-blue:after {
  background: #00AFF0 !important;
}
.after-fg-blue:after {
  color: #00AFF0 !important;
}
.bg-hover-blue:hover {
  background: #00AFF0 !important;
}
.bg-active-blue:active {
  background: #00AFF0 !important;
}
.bg-focus-blue:focus {
  background: #00AFF0 !important;
}
.fg-hover-blue:hover {
  color: #00AFF0 !important;
}
.fg-active-blue:active {
  color: #00AFF0 !important;
}
.fg-focus-blue:focus {
  color: #00AFF0 !important;
}
.bd-teal {
  border-color: #00aba9 !important;
}
.ol-teal {
  outline-color: #00aba9 !important;
}
.op-teal {
  background-color: rgba(0, 171, 169, 0.7);
}
.ribbed-teal {
  background: #00aba9 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-teal:before {
  background: #00aba9 !important;
}
.before-fg-teal:before {
  color: #00aba9 !important;
}
.after-bg-teal:after {
  background: #00aba9 !important;
}
.after-fg-teal:after {
  color: #00aba9 !important;
}
.bg-hover-teal:hover {
  background: #00aba9 !important;
}
.bg-active-teal:active {
  background: #00aba9 !important;
}
.bg-focus-teal:focus {
  background: #00aba9 !important;
}
.fg-hover-teal:hover {
  color: #00aba9 !important;
}
.fg-active-teal:active {
  color: #00aba9 !important;
}
.fg-focus-teal:focus {
  color: #00aba9 !important;
}
.bd-cyan {
  border-color: #1ba1e2 !important;
}
.ol-cyan {
  outline-color: #1ba1e2 !important;
}
.op-cyan {
  background-color: rgba(27, 161, 226, 0.7);
}
.ribbed-cyan {
  background: #1ba1e2 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-cyan:before {
  background: #1ba1e2 !important;
}
.before-fg-cyan:before {
  color: #1ba1e2 !important;
}
.after-bg-cyan:after {
  background: #1ba1e2 !important;
}
.after-fg-cyan:after {
  color: #1ba1e2 !important;
}
.bg-hover-cyan:hover {
  background: #1ba1e2 !important;
}
.bg-active-cyan:active {
  background: #1ba1e2 !important;
}
.bg-focus-cyan:focus {
  background: #1ba1e2 !important;
}
.fg-hover-cyan:hover {
  color: #1ba1e2 !important;
}
.fg-active-cyan:active {
  color: #1ba1e2 !important;
}
.fg-focus-cyan:focus {
  color: #1ba1e2 !important;
}
.bd-cobalt {
  border-color: #0050ef !important;
}
.ol-cobalt {
  outline-color: #0050ef !important;
}
.op-cobalt {
  background-color: rgba(0, 80, 239, 0.7);
}
.ribbed-cobalt {
  background: #0050ef linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-cobalt:before {
  background: #0050ef !important;
}
.before-fg-cobalt:before {
  color: #0050ef !important;
}
.after-bg-cobalt:after {
  background: #0050ef !important;
}
.after-fg-cobalt:after {
  color: #0050ef !important;
}
.bg-hover-cobalt:hover {
  background: #0050ef !important;
}
.bg-active-cobalt:active {
  background: #0050ef !important;
}
.bg-focus-cobalt:focus {
  background: #0050ef !important;
}
.fg-hover-cobalt:hover {
  color: #0050ef !important;
}
.fg-active-cobalt:active {
  color: #0050ef !important;
}
.fg-focus-cobalt:focus {
  color: #0050ef !important;
}
.bd-indigo {
  border-color: #6a00ff !important;
}
.ol-indigo {
  outline-color: #6a00ff !important;
}
.op-indigo {
  background-color: rgba(106, 0, 255, 0.7);
}
.ribbed-indigo {
  background: #6a00ff linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-indigo:before {
  background: #6a00ff !important;
}
.before-fg-indigo:before {
  color: #6a00ff !important;
}
.after-bg-indigo:after {
  background: #6a00ff !important;
}
.after-fg-indigo:after {
  color: #6a00ff !important;
}
.bg-hover-indigo:hover {
  background: #6a00ff !important;
}
.bg-active-indigo:active {
  background: #6a00ff !important;
}
.bg-focus-indigo:focus {
  background: #6a00ff !important;
}
.fg-hover-indigo:hover {
  color: #6a00ff !important;
}
.fg-active-indigo:active {
  color: #6a00ff !important;
}
.fg-focus-indigo:focus {
  color: #6a00ff !important;
}
.bd-violet {
  border-color: #aa00ff !important;
}
.ol-violet {
  outline-color: #aa00ff !important;
}
.op-violet {
  background-color: rgba(170, 0, 255, 0.7);
}
.ribbed-violet {
  background: #aa00ff linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-violet:before {
  background: #aa00ff !important;
}
.before-fg-violet:before {
  color: #aa00ff !important;
}
.after-bg-violet:after {
  background: #aa00ff !important;
}
.after-fg-violet:after {
  color: #aa00ff !important;
}
.bg-hover-violet:hover {
  background: #aa00ff !important;
}
.bg-active-violet:active {
  background: #aa00ff !important;
}
.bg-focus-violet:focus {
  background: #aa00ff !important;
}
.fg-hover-violet:hover {
  color: #aa00ff !important;
}
.fg-active-violet:active {
  color: #aa00ff !important;
}
.fg-focus-violet:focus {
  color: #aa00ff !important;
}
.bd-pink {
  border-color: #dc4fad !important;
}
.ol-pink {
  outline-color: #dc4fad !important;
}
.op-pink {
  background-color: rgba(220, 79, 173, 0.7);
}
.ribbed-pink {
  background: #dc4fad linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-pink:before {
  background: #dc4fad !important;
}
.before-fg-pink:before {
  color: #dc4fad !important;
}
.after-bg-pink:after {
  background: #dc4fad !important;
}
.after-fg-pink:after {
  color: #dc4fad !important;
}
.bg-hover-pink:hover {
  background: #dc4fad !important;
}
.bg-active-pink:active {
  background: #dc4fad !important;
}
.bg-focus-pink:focus {
  background: #dc4fad !important;
}
.fg-hover-pink:hover {
  color: #dc4fad !important;
}
.fg-active-pink:active {
  color: #dc4fad !important;
}
.fg-focus-pink:focus {
  color: #dc4fad !important;
}
.bd-magenta {
  border-color: #d80073 !important;
}
.ol-magenta {
  outline-color: #d80073 !important;
}
.op-magenta {
  background-color: rgba(216, 0, 115, 0.7);
}
.ribbed-magenta {
  background: #d80073 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-magenta:before {
  background: #d80073 !important;
}
.before-fg-magenta:before {
  color: #d80073 !important;
}
.after-bg-magenta:after {
  background: #d80073 !important;
}
.after-fg-magenta:after {
  color: #d80073 !important;
}
.bg-hover-magenta:hover {
  background: #d80073 !important;
}
.bg-active-magenta:active {
  background: #d80073 !important;
}
.bg-focus-magenta:focus {
  background: #d80073 !important;
}
.fg-hover-magenta:hover {
  color: #d80073 !important;
}
.fg-active-magenta:active {
  color: #d80073 !important;
}
.fg-focus-magenta:focus {
  color: #d80073 !important;
}
.bd-crimson {
  border-color: #a20025 !important;
}
.ol-crimson {
  outline-color: #a20025 !important;
}
.op-crimson {
  background-color: rgba(162, 0, 37, 0.7);
}
.ribbed-crimson {
  background: #a20025 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-crimson:before {
  background: #a20025 !important;
}
.before-fg-crimson:before {
  color: #a20025 !important;
}
.after-bg-crimson:after {
  background: #a20025 !important;
}
.after-fg-crimson:after {
  color: #a20025 !important;
}
.bg-hover-crimson:hover {
  background: #a20025 !important;
}
.bg-active-crimson:active {
  background: #a20025 !important;
}
.bg-focus-crimson:focus {
  background: #a20025 !important;
}
.fg-hover-crimson:hover {
  color: #a20025 !important;
}
.fg-active-crimson:active {
  color: #a20025 !important;
}
.fg-focus-crimson:focus {
  color: #a20025 !important;
}
.bd-red {
  border-color: #CE352C !important;
}
.ol-red {
  outline-color: #CE352C !important;
}
.op-red {
  background-color: rgba(206, 53, 44, 0.7);
}
.ribbed-red {
  background: #CE352C linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-red:before {
  background: #CE352C !important;
}
.before-fg-red:before {
  color: #CE352C !important;
}
.after-bg-red:after {
  background: #CE352C !important;
}
.after-fg-red:after {
  color: #CE352C !important;
}
.bg-hover-red:hover {
  background: #CE352C !important;
}
.bg-active-red:active {
  background: #CE352C !important;
}
.bg-focus-red:focus {
  background: #CE352C !important;
}
.fg-hover-red:hover {
  color: #CE352C !important;
}
.fg-active-red:active {
  color: #CE352C !important;
}
.fg-focus-red:focus {
  color: #CE352C !important;
}
.bd-orange {
  border-color: #fa6800 !important;
}
.ol-orange {
  outline-color: #fa6800 !important;
}
.op-orange {
  background-color: rgba(250, 104, 0, 0.7);
}
.ribbed-orange {
  background: #fa6800 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-orange:before {
  background: #fa6800 !important;
}
.before-fg-orange:before {
  color: #fa6800 !important;
}
.after-bg-orange:after {
  background: #fa6800 !important;
}
.after-fg-orange:after {
  color: #fa6800 !important;
}
.bg-hover-orange:hover {
  background: #fa6800 !important;
}
.bg-active-orange:active {
  background: #fa6800 !important;
}
.bg-focus-orange:focus {
  background: #fa6800 !important;
}
.fg-hover-orange:hover {
  color: #fa6800 !important;
}
.fg-active-orange:active {
  color: #fa6800 !important;
}
.fg-focus-orange:focus {
  color: #fa6800 !important;
}
.bd-amber {
  border-color: #f0a30a !important;
}
.ol-amber {
  outline-color: #f0a30a !important;
}
.op-amber {
  background-color: rgba(240, 163, 10, 0.7);
}
.ribbed-amber {
  background: #f0a30a linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-amber:before {
  background: #f0a30a !important;
}
.before-fg-amber:before {
  color: #f0a30a !important;
}
.after-bg-amber:after {
  background: #f0a30a !important;
}
.after-fg-amber:after {
  color: #f0a30a !important;
}
.bg-hover-amber:hover {
  background: #f0a30a !important;
}
.bg-active-amber:active {
  background: #f0a30a !important;
}
.bg-focus-amber:focus {
  background: #f0a30a !important;
}
.fg-hover-amber:hover {
  color: #f0a30a !important;
}
.fg-active-amber:active {
  color: #f0a30a !important;
}
.fg-focus-amber:focus {
  color: #f0a30a !important;
}
.bd-yellow {
  border-color: #e3c800 !important;
}
.ol-yellow {
  outline-color: #e3c800 !important;
}
.op-yellow {
  background-color: rgba(227, 200, 0, 0.7);
}
.ribbed-yellow {
  background: #e3c800 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-yellow:before {
  background: #e3c800 !important;
}
.before-fg-yellow:before {
  color: #e3c800 !important;
}
.after-bg-yellow:after {
  background: #e3c800 !important;
}
.after-fg-yellow:after {
  color: #e3c800 !important;
}
.bg-hover-yellow:hover {
  background: #e3c800 !important;
}
.bg-active-yellow:active {
  background: #e3c800 !important;
}
.bg-focus-yellow:focus {
  background: #e3c800 !important;
}
.fg-hover-yellow:hover {
  color: #e3c800 !important;
}
.fg-active-yellow:active {
  color: #e3c800 !important;
}
.fg-focus-yellow:focus {
  color: #e3c800 !important;
}
.bd-brown {
  border-color: #825a2c !important;
}
.ol-brown {
  outline-color: #825a2c !important;
}
.op-brown {
  background-color: rgba(130, 90, 44, 0.7);
}
.ribbed-brown {
  background: #825a2c linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-brown:before {
  background: #825a2c !important;
}
.before-fg-brown:before {
  color: #825a2c !important;
}
.after-bg-brown:after {
  background: #825a2c !important;
}
.after-fg-brown:after {
  color: #825a2c !important;
}
.bg-hover-brown:hover {
  background: #825a2c !important;
}
.bg-active-brown:active {
  background: #825a2c !important;
}
.bg-focus-brown:focus {
  background: #825a2c !important;
}
.fg-hover-brown:hover {
  color: #825a2c !important;
}
.fg-active-brown:active {
  color: #825a2c !important;
}
.fg-focus-brown:focus {
  color: #825a2c !important;
}
.bd-olive {
  border-color: #6d8764 !important;
}
.ol-olive {
  outline-color: #6d8764 !important;
}
.op-olive {
  background-color: rgba(109, 135, 100, 0.7);
}
.ribbed-olive {
  background: #6d8764 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-olive:before {
  background: #6d8764 !important;
}
.before-fg-olive:before {
  color: #6d8764 !important;
}
.after-bg-olive:after {
  background: #6d8764 !important;
}
.after-fg-olive:after {
  color: #6d8764 !important;
}
.bg-hover-olive:hover {
  background: #6d8764 !important;
}
.bg-active-olive:active {
  background: #6d8764 !important;
}
.bg-focus-olive:focus {
  background: #6d8764 !important;
}
.fg-hover-olive:hover {
  color: #6d8764 !important;
}
.fg-active-olive:active {
  color: #6d8764 !important;
}
.fg-focus-olive:focus {
  color: #6d8764 !important;
}
.bd-steel {
  border-color: #647687 !important;
}
.ol-steel {
  outline-color: #647687 !important;
}
.op-steel {
  background-color: rgba(100, 118, 135, 0.7);
}
.ribbed-steel {
  background: #647687 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-steel:before {
  background: #647687 !important;
}
.before-fg-steel:before {
  color: #647687 !important;
}
.after-bg-steel:after {
  background: #647687 !important;
}
.after-fg-steel:after {
  color: #647687 !important;
}
.bg-hover-steel:hover {
  background: #647687 !important;
}
.bg-active-steel:active {
  background: #647687 !important;
}
.bg-focus-steel:focus {
  background: #647687 !important;
}
.fg-hover-steel:hover {
  color: #647687 !important;
}
.fg-active-steel:active {
  color: #647687 !important;
}
.fg-focus-steel:focus {
  color: #647687 !important;
}
.bd-mauve {
  border-color: #76608a !important;
}
.ol-mauve {
  outline-color: #76608a !important;
}
.op-mauve {
  background-color: rgba(118, 96, 138, 0.7);
}
.ribbed-mauve {
  background: #76608a linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-mauve:before {
  background: #76608a !important;
}
.before-fg-mauve:before {
  color: #76608a !important;
}
.after-bg-mauve:after {
  background: #76608a !important;
}
.after-fg-mauve:after {
  color: #76608a !important;
}
.bg-hover-mauve:hover {
  background: #76608a !important;
}
.bg-active-mauve:active {
  background: #76608a !important;
}
.bg-focus-mauve:focus {
  background: #76608a !important;
}
.fg-hover-mauve:hover {
  color: #76608a !important;
}
.fg-active-mauve:active {
  color: #76608a !important;
}
.fg-focus-mauve:focus {
  color: #76608a !important;
}
.bd-taupe {
  border-color: #87794e !important;
}
.ol-taupe {
  outline-color: #87794e !important;
}
.op-taupe {
  background-color: rgba(135, 121, 78, 0.7);
}
.ribbed-taupe {
  background: #87794e linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-taupe:before {
  background: #87794e !important;
}
.before-fg-taupe:before {
  color: #87794e !important;
}
.after-bg-taupe:after {
  background: #87794e !important;
}
.after-fg-taupe:after {
  color: #87794e !important;
}
.bg-hover-taupe:hover {
  background: #87794e !important;
}
.bg-active-taupe:active {
  background: #87794e !important;
}
.bg-focus-taupe:focus {
  background: #87794e !important;
}
.fg-hover-taupe:hover {
  color: #87794e !important;
}
.fg-active-taupe:active {
  color: #87794e !important;
}
.fg-focus-taupe:focus {
  color: #87794e !important;
}
.bd-dark {
  border-color: #1d1d1d !important;
}
.ol-dark {
  outline-color: #1d1d1d !important;
}
.op-dark {
  background-color: rgba(29, 29, 29, 0.7);
}
.ribbed-dark {
  background: #1d1d1d linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-dark:before {
  background: #1d1d1d !important;
}
.before-fg-dark:before {
  color: #1d1d1d !important;
}
.after-bg-dark:after {
  background: #1d1d1d !important;
}
.after-fg-dark:after {
  color: #1d1d1d !important;
}
.bg-hover-dark:hover {
  background: #1d1d1d !important;
}
.bg-active-dark:active {
  background: #1d1d1d !important;
}
.bg-focus-dark:focus {
  background: #1d1d1d !important;
}
.fg-hover-dark:hover {
  color: #1d1d1d !important;
}
.fg-active-dark:active {
  color: #1d1d1d !important;
}
.fg-focus-dark:focus {
  color: #1d1d1d !important;
}
.bd-darkBrown {
  border-color: #63362F !important;
}
.ol-darkBrown {
  outline-color: #63362F !important;
}
.op-darkBrown {
  background-color: rgba(99, 54, 47, 0.7);
}
.ribbed-darkBrown {
  background: #63362F linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkBrown:before {
  background: #63362F !important;
}
.before-fg-darkBrown:before {
  color: #63362F !important;
}
.after-bg-darkBrown:after {
  background: #63362F !important;
}
.after-fg-darkBrown:after {
  color: #63362F !important;
}
.bg-hover-darkBrown:hover {
  background: #63362F !important;
}
.bg-active-darkBrown:active {
  background: #63362F !important;
}
.bg-focus-darkBrown:focus {
  background: #63362F !important;
}
.fg-hover-darkBrown:hover {
  color: #63362F !important;
}
.fg-active-darkBrown:active {
  color: #63362F !important;
}
.fg-focus-darkBrown:focus {
  color: #63362F !important;
}
.bd-darkCrimson {
  border-color: #640024 !important;
}
.ol-darkCrimson {
  outline-color: #640024 !important;
}
.op-darkCrimson {
  background-color: rgba(100, 0, 36, 0.7);
}
.ribbed-darkCrimson {
  background: #640024 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkCrimson:before {
  background: #640024 !important;
}
.before-fg-darkCrimson:before {
  color: #640024 !important;
}
.after-bg-darkCrimson:after {
  background: #640024 !important;
}
.after-fg-darkCrimson:after {
  color: #640024 !important;
}
.bg-hover-darkCrimson:hover {
  background: #640024 !important;
}
.bg-active-darkCrimson:active {
  background: #640024 !important;
}
.bg-focus-darkCrimson:focus {
  background: #640024 !important;
}
.fg-hover-darkCrimson:hover {
  color: #640024 !important;
}
.fg-active-darkCrimson:active {
  color: #640024 !important;
}
.fg-focus-darkCrimson:focus {
  color: #640024 !important;
}
.bd-darkMagenta {
  border-color: #81003C !important;
}
.ol-darkMagenta {
  outline-color: #81003C !important;
}
.op-darkMagenta {
  background-color: rgba(129, 0, 60, 0.7);
}
.ribbed-darkMagenta {
  background: #81003C linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkMagenta:before {
  background: #81003C !important;
}
.before-fg-darkMagenta:before {
  color: #81003C !important;
}
.after-bg-darkMagenta:after {
  background: #81003C !important;
}
.after-fg-darkMagenta:after {
  color: #81003C !important;
}
.bg-hover-darkMagenta:hover {
  background: #81003C !important;
}
.bg-active-darkMagenta:active {
  background: #81003C !important;
}
.bg-focus-darkMagenta:focus {
  background: #81003C !important;
}
.fg-hover-darkMagenta:hover {
  color: #81003C !important;
}
.fg-active-darkMagenta:active {
  color: #81003C !important;
}
.fg-focus-darkMagenta:focus {
  color: #81003C !important;
}
.bd-darkIndigo {
  border-color: #4B0096 !important;
}
.ol-darkIndigo {
  outline-color: #4B0096 !important;
}
.op-darkIndigo {
  background-color: rgba(75, 0, 150, 0.7);
}
.ribbed-darkIndigo {
  background: #4B0096 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkIndigo:before {
  background: #4B0096 !important;
}
.before-fg-darkIndigo:before {
  color: #4B0096 !important;
}
.after-bg-darkIndigo:after {
  background: #4B0096 !important;
}
.after-fg-darkIndigo:after {
  color: #4B0096 !important;
}
.bg-hover-darkIndigo:hover {
  background: #4B0096 !important;
}
.bg-active-darkIndigo:active {
  background: #4B0096 !important;
}
.bg-focus-darkIndigo:focus {
  background: #4B0096 !important;
}
.fg-hover-darkIndigo:hover {
  color: #4B0096 !important;
}
.fg-active-darkIndigo:active {
  color: #4B0096 !important;
}
.fg-focus-darkIndigo:focus {
  color: #4B0096 !important;
}
.bd-darkCyan {
  border-color: #1B6EAE !important;
}
.ol-darkCyan {
  outline-color: #1B6EAE !important;
}
.op-darkCyan {
  background-color: rgba(27, 110, 174, 0.7);
}
.ribbed-darkCyan {
  background: #1B6EAE linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkCyan:before {
  background: #1B6EAE !important;
}
.before-fg-darkCyan:before {
  color: #1B6EAE !important;
}
.after-bg-darkCyan:after {
  background: #1B6EAE !important;
}
.after-fg-darkCyan:after {
  color: #1B6EAE !important;
}
.bg-hover-darkCyan:hover {
  background: #1B6EAE !important;
}
.bg-active-darkCyan:active {
  background: #1B6EAE !important;
}
.bg-focus-darkCyan:focus {
  background: #1B6EAE !important;
}
.fg-hover-darkCyan:hover {
  color: #1B6EAE !important;
}
.fg-active-darkCyan:active {
  color: #1B6EAE !important;
}
.fg-focus-darkCyan:focus {
  color: #1B6EAE !important;
}
.bd-darkCobalt {
  border-color: #00356A !important;
}
.ol-darkCobalt {
  outline-color: #00356A !important;
}
.op-darkCobalt {
  background-color: rgba(0, 53, 106, 0.7);
}
.ribbed-darkCobalt {
  background: #00356A linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkCobalt:before {
  background: #00356A !important;
}
.before-fg-darkCobalt:before {
  color: #00356A !important;
}
.after-bg-darkCobalt:after {
  background: #00356A !important;
}
.after-fg-darkCobalt:after {
  color: #00356A !important;
}
.bg-hover-darkCobalt:hover {
  background: #00356A !important;
}
.bg-active-darkCobalt:active {
  background: #00356A !important;
}
.bg-focus-darkCobalt:focus {
  background: #00356A !important;
}
.fg-hover-darkCobalt:hover {
  color: #00356A !important;
}
.fg-active-darkCobalt:active {
  color: #00356A !important;
}
.fg-focus-darkCobalt:focus {
  color: #00356A !important;
}
.bd-darkTeal {
  border-color: #004050 !important;
}
.ol-darkTeal {
  outline-color: #004050 !important;
}
.op-darkTeal {
  background-color: rgba(0, 64, 80, 0.7);
}
.ribbed-darkTeal {
  background: #004050 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkTeal:before {
  background: #004050 !important;
}
.before-fg-darkTeal:before {
  color: #004050 !important;
}
.after-bg-darkTeal:after {
  background: #004050 !important;
}
.after-fg-darkTeal:after {
  color: #004050 !important;
}
.bg-hover-darkTeal:hover {
  background: #004050 !important;
}
.bg-active-darkTeal:active {
  background: #004050 !important;
}
.bg-focus-darkTeal:focus {
  background: #004050 !important;
}
.fg-hover-darkTeal:hover {
  color: #004050 !important;
}
.fg-active-darkTeal:active {
  color: #004050 !important;
}
.fg-focus-darkTeal:focus {
  color: #004050 !important;
}
.bd-darkEmerald {
  border-color: #003e00 !important;
}
.ol-darkEmerald {
  outline-color: #003e00 !important;
}
.op-darkEmerald {
  background-color: rgba(0, 62, 0, 0.7);
}
.ribbed-darkEmerald {
  background: #003e00 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkEmerald:before {
  background: #003e00 !important;
}
.before-fg-darkEmerald:before {
  color: #003e00 !important;
}
.after-bg-darkEmerald:after {
  background: #003e00 !important;
}
.after-fg-darkEmerald:after {
  color: #003e00 !important;
}
.bg-hover-darkEmerald:hover {
  background: #003e00 !important;
}
.bg-active-darkEmerald:active {
  background: #003e00 !important;
}
.bg-focus-darkEmerald:focus {
  background: #003e00 !important;
}
.fg-hover-darkEmerald:hover {
  color: #003e00 !important;
}
.fg-active-darkEmerald:active {
  color: #003e00 !important;
}
.fg-focus-darkEmerald:focus {
  color: #003e00 !important;
}
.bd-darkGreen {
  border-color: #128023 !important;
}
.ol-darkGreen {
  outline-color: #128023 !important;
}
.op-darkGreen {
  background-color: rgba(18, 128, 35, 0.7);
}
.ribbed-darkGreen {
  background: #128023 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkGreen:before {
  background: #128023 !important;
}
.before-fg-darkGreen:before {
  color: #128023 !important;
}
.after-bg-darkGreen:after {
  background: #128023 !important;
}
.after-fg-darkGreen:after {
  color: #128023 !important;
}
.bg-hover-darkGreen:hover {
  background: #128023 !important;
}
.bg-active-darkGreen:active {
  background: #128023 !important;
}
.bg-focus-darkGreen:focus {
  background: #128023 !important;
}
.fg-hover-darkGreen:hover {
  color: #128023 !important;
}
.fg-active-darkGreen:active {
  color: #128023 !important;
}
.fg-focus-darkGreen:focus {
  color: #128023 !important;
}
.bd-darkOrange {
  border-color: #bf5a15 !important;
}
.ol-darkOrange {
  outline-color: #bf5a15 !important;
}
.op-darkOrange {
  background-color: rgba(191, 90, 21, 0.7);
}
.ribbed-darkOrange {
  background: #bf5a15 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkOrange:before {
  background: #bf5a15 !important;
}
.before-fg-darkOrange:before {
  color: #bf5a15 !important;
}
.after-bg-darkOrange:after {
  background: #bf5a15 !important;
}
.after-fg-darkOrange:after {
  color: #bf5a15 !important;
}
.bg-hover-darkOrange:hover {
  background: #bf5a15 !important;
}
.bg-active-darkOrange:active {
  background: #bf5a15 !important;
}
.bg-focus-darkOrange:focus {
  background: #bf5a15 !important;
}
.fg-hover-darkOrange:hover {
  color: #bf5a15 !important;
}
.fg-active-darkOrange:active {
  color: #bf5a15 !important;
}
.fg-focus-darkOrange:focus {
  color: #bf5a15 !important;
}
.bd-darkRed {
  border-color: #9a1616 !important;
}
.ol-darkRed {
  outline-color: #9a1616 !important;
}
.op-darkRed {
  background-color: rgba(154, 22, 22, 0.7);
}
.ribbed-darkRed {
  background: #9a1616 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkRed:before {
  background: #9a1616 !important;
}
.before-fg-darkRed:before {
  color: #9a1616 !important;
}
.after-bg-darkRed:after {
  background: #9a1616 !important;
}
.after-fg-darkRed:after {
  color: #9a1616 !important;
}
.bg-hover-darkRed:hover {
  background: #9a1616 !important;
}
.bg-active-darkRed:active {
  background: #9a1616 !important;
}
.bg-focus-darkRed:focus {
  background: #9a1616 !important;
}
.fg-hover-darkRed:hover {
  color: #9a1616 !important;
}
.fg-active-darkRed:active {
  color: #9a1616 !important;
}
.fg-focus-darkRed:focus {
  color: #9a1616 !important;
}
.bd-darkPink {
  border-color: #9a165a !important;
}
.ol-darkPink {
  outline-color: #9a165a !important;
}
.op-darkPink {
  background-color: rgba(154, 22, 90, 0.7);
}
.ribbed-darkPink {
  background: #9a165a linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkPink:before {
  background: #9a165a !important;
}
.before-fg-darkPink:before {
  color: #9a165a !important;
}
.after-bg-darkPink:after {
  background: #9a165a !important;
}
.after-fg-darkPink:after {
  color: #9a165a !important;
}
.bg-hover-darkPink:hover {
  background: #9a165a !important;
}
.bg-active-darkPink:active {
  background: #9a165a !important;
}
.bg-focus-darkPink:focus {
  background: #9a165a !important;
}
.fg-hover-darkPink:hover {
  color: #9a165a !important;
}
.fg-active-darkPink:active {
  color: #9a165a !important;
}
.fg-focus-darkPink:focus {
  color: #9a165a !important;
}
.bd-darkViolet {
  border-color: #57169a !important;
}
.ol-darkViolet {
  outline-color: #57169a !important;
}
.op-darkViolet {
  background-color: rgba(87, 22, 154, 0.7);
}
.ribbed-darkViolet {
  background: #57169a linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkViolet:before {
  background: #57169a !important;
}
.before-fg-darkViolet:before {
  color: #57169a !important;
}
.after-bg-darkViolet:after {
  background: #57169a !important;
}
.after-fg-darkViolet:after {
  color: #57169a !important;
}
.bg-hover-darkViolet:hover {
  background: #57169a !important;
}
.bg-active-darkViolet:active {
  background: #57169a !important;
}
.bg-focus-darkViolet:focus {
  background: #57169a !important;
}
.fg-hover-darkViolet:hover {
  color: #57169a !important;
}
.fg-active-darkViolet:active {
  color: #57169a !important;
}
.fg-focus-darkViolet:focus {
  color: #57169a !important;
}
.bd-darkBlue {
  border-color: #16499a !important;
}
.ol-darkBlue {
  outline-color: #16499a !important;
}
.op-darkBlue {
  background-color: rgba(22, 73, 154, 0.7);
}
.ribbed-darkBlue {
  background: #16499a linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkBlue:before {
  background: #16499a !important;
}
.before-fg-darkBlue:before {
  color: #16499a !important;
}
.after-bg-darkBlue:after {
  background: #16499a !important;
}
.after-fg-darkBlue:after {
  color: #16499a !important;
}
.bg-hover-darkBlue:hover {
  background: #16499a !important;
}
.bg-active-darkBlue:active {
  background: #16499a !important;
}
.bg-focus-darkBlue:focus {
  background: #16499a !important;
}
.fg-hover-darkBlue:hover {
  color: #16499a !important;
}
.fg-active-darkBlue:active {
  color: #16499a !important;
}
.fg-focus-darkBlue:focus {
  color: #16499a !important;
}
.bd-lightBlue {
  border-color: #4390df !important;
}
.ol-lightBlue {
  outline-color: #4390df !important;
}
.op-lightBlue {
  background-color: rgba(67, 144, 223, 0.7);
}
.ribbed-lightBlue {
  background: #4390df linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightBlue:before {
  background: #4390df !important;
}
.before-fg-lightBlue:before {
  color: #4390df !important;
}
.after-bg-lightBlue:after {
  background: #4390df !important;
}
.after-fg-lightBlue:after {
  color: #4390df !important;
}
.bg-hover-lightBlue:hover {
  background: #4390df !important;
}
.bg-active-lightBlue:active {
  background: #4390df !important;
}
.bg-focus-lightBlue:focus {
  background: #4390df !important;
}
.fg-hover-lightBlue:hover {
  color: #4390df !important;
}
.fg-active-lightBlue:active {
  color: #4390df !important;
}
.fg-focus-lightBlue:focus {
  color: #4390df !important;
}
.bd-lighterBlue {
  border-color: #00CCFF !important;
}
.ol-lighterBlue {
  outline-color: #00CCFF !important;
}
.op-lighterBlue {
  background-color: rgba(0, 204, 255, 0.7);
}
.ribbed-lighterBlue {
  background: #00CCFF linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lighterBlue:before {
  background: #00CCFF !important;
}
.before-fg-lighterBlue:before {
  color: #00CCFF !important;
}
.after-bg-lighterBlue:after {
  background: #00CCFF !important;
}
.after-fg-lighterBlue:after {
  color: #00CCFF !important;
}
.bg-hover-lighterBlue:hover {
  background: #00CCFF !important;
}
.bg-active-lighterBlue:active {
  background: #00CCFF !important;
}
.bg-focus-lighterBlue:focus {
  background: #00CCFF !important;
}
.fg-hover-lighterBlue:hover {
  color: #00CCFF !important;
}
.fg-active-lighterBlue:active {
  color: #00CCFF !important;
}
.fg-focus-lighterBlue:focus {
  color: #00CCFF !important;
}
.bd-lightTeal {
  border-color: #45fffd !important;
}
.ol-lightTeal {
  outline-color: #45fffd !important;
}
.op-lightTeal {
  background-color: rgba(69, 255, 253, 0.7);
}
.ribbed-lightTeal {
  background: #45fffd linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightTeal:before {
  background: #45fffd !important;
}
.before-fg-lightTeal:before {
  color: #45fffd !important;
}
.after-bg-lightTeal:after {
  background: #45fffd !important;
}
.after-fg-lightTeal:after {
  color: #45fffd !important;
}
.bg-hover-lightTeal:hover {
  background: #45fffd !important;
}
.bg-active-lightTeal:active {
  background: #45fffd !important;
}
.bg-focus-lightTeal:focus {
  background: #45fffd !important;
}
.fg-hover-lightTeal:hover {
  color: #45fffd !important;
}
.fg-active-lightTeal:active {
  color: #45fffd !important;
}
.fg-focus-lightTeal:focus {
  color: #45fffd !important;
}
.bd-lightOlive {
  border-color: #78aa1c !important;
}
.ol-lightOlive {
  outline-color: #78aa1c !important;
}
.op-lightOlive {
  background-color: rgba(120, 170, 28, 0.7);
}
.ribbed-lightOlive {
  background: #78aa1c linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightOlive:before {
  background: #78aa1c !important;
}
.before-fg-lightOlive:before {
  color: #78aa1c !important;
}
.after-bg-lightOlive:after {
  background: #78aa1c !important;
}
.after-fg-lightOlive:after {
  color: #78aa1c !important;
}
.bg-hover-lightOlive:hover {
  background: #78aa1c !important;
}
.bg-active-lightOlive:active {
  background: #78aa1c !important;
}
.bg-focus-lightOlive:focus {
  background: #78aa1c !important;
}
.fg-hover-lightOlive:hover {
  color: #78aa1c !important;
}
.fg-active-lightOlive:active {
  color: #78aa1c !important;
}
.fg-focus-lightOlive:focus {
  color: #78aa1c !important;
}
.bd-lightOrange {
  border-color: #ffc194 !important;
}
.ol-lightOrange {
  outline-color: #ffc194 !important;
}
.op-lightOrange {
  background-color: rgba(255, 193, 148, 0.7);
}
.ribbed-lightOrange {
  background: #ffc194 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightOrange:before {
  background: #ffc194 !important;
}
.before-fg-lightOrange:before {
  color: #ffc194 !important;
}
.after-bg-lightOrange:after {
  background: #ffc194 !important;
}
.after-fg-lightOrange:after {
  color: #ffc194 !important;
}
.bg-hover-lightOrange:hover {
  background: #ffc194 !important;
}
.bg-active-lightOrange:active {
  background: #ffc194 !important;
}
.bg-focus-lightOrange:focus {
  background: #ffc194 !important;
}
.fg-hover-lightOrange:hover {
  color: #ffc194 !important;
}
.fg-active-lightOrange:active {
  color: #ffc194 !important;
}
.fg-focus-lightOrange:focus {
  color: #ffc194 !important;
}
.bd-lightPink {
  border-color: #f472d0 !important;
}
.ol-lightPink {
  outline-color: #f472d0 !important;
}
.op-lightPink {
  background-color: rgba(244, 114, 208, 0.7);
}
.ribbed-lightPink {
  background: #f472d0 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightPink:before {
  background: #f472d0 !important;
}
.before-fg-lightPink:before {
  color: #f472d0 !important;
}
.after-bg-lightPink:after {
  background: #f472d0 !important;
}
.after-fg-lightPink:after {
  color: #f472d0 !important;
}
.bg-hover-lightPink:hover {
  background: #f472d0 !important;
}
.bg-active-lightPink:active {
  background: #f472d0 !important;
}
.bg-focus-lightPink:focus {
  background: #f472d0 !important;
}
.fg-hover-lightPink:hover {
  color: #f472d0 !important;
}
.fg-active-lightPink:active {
  color: #f472d0 !important;
}
.fg-focus-lightPink:focus {
  color: #f472d0 !important;
}
.bd-lightRed {
  border-color: #da5a53 !important;
}
.ol-lightRed {
  outline-color: #da5a53 !important;
}
.op-lightRed {
  background-color: rgba(218, 90, 83, 0.7);
}
.ribbed-lightRed {
  background: #da5a53 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightRed:before {
  background: #da5a53 !important;
}
.before-fg-lightRed:before {
  color: #da5a53 !important;
}
.after-bg-lightRed:after {
  background: #da5a53 !important;
}
.after-fg-lightRed:after {
  color: #da5a53 !important;
}
.bg-hover-lightRed:hover {
  background: #da5a53 !important;
}
.bg-active-lightRed:active {
  background: #da5a53 !important;
}
.bg-focus-lightRed:focus {
  background: #da5a53 !important;
}
.fg-hover-lightRed:hover {
  color: #da5a53 !important;
}
.fg-active-lightRed:active {
  color: #da5a53 !important;
}
.fg-focus-lightRed:focus {
  color: #da5a53 !important;
}
.bd-lightGreen {
  border-color: #7ad61d !important;
}
.ol-lightGreen {
  outline-color: #7ad61d !important;
}
.op-lightGreen {
  background-color: rgba(122, 214, 29, 0.7);
}
.ribbed-lightGreen {
  background: #7ad61d linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightGreen:before {
  background: #7ad61d !important;
}
.before-fg-lightGreen:before {
  color: #7ad61d !important;
}
.after-bg-lightGreen:after {
  background: #7ad61d !important;
}
.after-fg-lightGreen:after {
  color: #7ad61d !important;
}
.bg-hover-lightGreen:hover {
  background: #7ad61d !important;
}
.bg-active-lightGreen:active {
  background: #7ad61d !important;
}
.bg-focus-lightGreen:focus {
  background: #7ad61d !important;
}
.fg-hover-lightGreen:hover {
  color: #7ad61d !important;
}
.fg-active-lightGreen:active {
  color: #7ad61d !important;
}
.fg-focus-lightGreen:focus {
  color: #7ad61d !important;
}
.bd-lightCyan {
  border-color: #59cde2 !important;
}
.ol-lightCyan {
  outline-color: #59cde2 !important;
}
.op-lightCyan {
  background-color: rgba(89, 205, 226, 0.7);
}
.ribbed-lightCyan {
  background: #59cde2 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightCyan:before {
  background: #59cde2 !important;
}
.before-fg-lightCyan:before {
  color: #59cde2 !important;
}
.after-bg-lightCyan:after {
  background: #59cde2 !important;
}
.after-fg-lightCyan:after {
  color: #59cde2 !important;
}
.bg-hover-lightCyan:hover {
  background: #59cde2 !important;
}
.bg-active-lightCyan:active {
  background: #59cde2 !important;
}
.bg-focus-lightCyan:focus {
  background: #59cde2 !important;
}
.fg-hover-lightCyan:hover {
  color: #59cde2 !important;
}
.fg-active-lightCyan:active {
  color: #59cde2 !important;
}
.fg-focus-lightCyan:focus {
  color: #59cde2 !important;
}
.bd-grayed {
  border-color: #585858 !important;
}
.ol-grayed {
  outline-color: #585858 !important;
}
.op-grayed {
  background-color: rgba(88, 88, 88, 0.7);
}
.ribbed-grayed {
  background: #585858 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-grayed:before {
  background: #585858 !important;
}
.before-fg-grayed:before {
  color: #585858 !important;
}
.after-bg-grayed:after {
  background: #585858 !important;
}
.after-fg-grayed:after {
  color: #585858 !important;
}
.bg-hover-grayed:hover {
  background: #585858 !important;
}
.bg-active-grayed:active {
  background: #585858 !important;
}
.bg-focus-grayed:focus {
  background: #585858 !important;
}
.fg-hover-grayed:hover {
  color: #585858 !important;
}
.fg-active-grayed:active {
  color: #585858 !important;
}
.fg-focus-grayed:focus {
  color: #585858 !important;
}
.bd-grayDarker {
  border-color: #222222 !important;
}
.ol-grayDarker {
  outline-color: #222222 !important;
}
.op-grayDarker {
  background-color: rgba(34, 34, 34, 0.7);
}
.ribbed-grayDarker {
  background: #222222 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-grayDarker:before {
  background: #222222 !important;
}
.before-fg-grayDarker:before {
  color: #222222 !important;
}
.after-bg-grayDarker:after {
  background: #222222 !important;
}
.after-fg-grayDarker:after {
  color: #222222 !important;
}
.bg-hover-grayDarker:hover {
  background: #222222 !important;
}
.bg-active-grayDarker:active {
  background: #222222 !important;
}
.bg-focus-grayDarker:focus {
  background: #222222 !important;
}
.fg-hover-grayDarker:hover {
  color: #222222 !important;
}
.fg-active-grayDarker:active {
  color: #222222 !important;
}
.fg-focus-grayDarker:focus {
  color: #222222 !important;
}
.bd-grayDark {
  border-color: #333333 !important;
}
.ol-grayDark {
  outline-color: #333333 !important;
}
.op-grayDark {
  background-color: rgba(51, 51, 51, 0.7);
}
.ribbed-grayDark {
  background: #333333 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-grayDark:before {
  background: #333333 !important;
}
.before-fg-grayDark:before {
  color: #333333 !important;
}
.after-bg-grayDark:after {
  background: #333333 !important;
}
.after-fg-grayDark:after {
  color: #333333 !important;
}
.bg-hover-grayDark:hover {
  background: #333333 !important;
}
.bg-active-grayDark:active {
  background: #333333 !important;
}
.bg-focus-grayDark:focus {
  background: #333333 !important;
}
.fg-hover-grayDark:hover {
  color: #333333 !important;
}
.fg-active-grayDark:active {
  color: #333333 !important;
}
.fg-focus-grayDark:focus {
  color: #333333 !important;
}
.bd-gray {
  border-color: #555555 !important;
}
.ol-gray {
  outline-color: #555555 !important;
}
.op-gray {
  background-color: rgba(85, 85, 85, 0.7);
}
.ribbed-gray {
  background: #555555 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-gray:before {
  background: #555555 !important;
}
.before-fg-gray:before {
  color: #555555 !important;
}
.after-bg-gray:after {
  background: #555555 !important;
}
.after-fg-gray:after {
  color: #555555 !important;
}
.bg-hover-gray:hover {
  background: #555555 !important;
}
.bg-active-gray:active {
  background: #555555 !important;
}
.bg-focus-gray:focus {
  background: #555555 !important;
}
.fg-hover-gray:hover {
  color: #555555 !important;
}
.fg-active-gray:active {
  color: #555555 !important;
}
.fg-focus-gray:focus {
  color: #555555 !important;
}
.bd-grayLight {
  border-color: #999999 !important;
}
.ol-grayLight {
  outline-color: #999999 !important;
}
.op-grayLight {
  background-color: rgba(153, 153, 153, 0.7);
}
.ribbed-grayLight {
  background: #999999 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-grayLight:before {
  background: #999999 !important;
}
.before-fg-grayLight:before {
  color: #999999 !important;
}
.after-bg-grayLight:after {
  background: #999999 !important;
}
.after-fg-grayLight:after {
  color: #999999 !important;
}
.bg-hover-grayLight:hover {
  background: #999999 !important;
}
.bg-active-grayLight:active {
  background: #999999 !important;
}
.bg-focus-grayLight:focus {
  background: #999999 !important;
}
.fg-hover-grayLight:hover {
  color: #999999 !important;
}
.fg-active-grayLight:active {
  color: #999999 !important;
}
.fg-focus-grayLight:focus {
  color: #999999 !important;
}
.bd-grayLighter {
  border-color: #eeeeee !important;
}
.ol-grayLighter {
  outline-color: #eeeeee !important;
}
.op-grayLighter {
  background-color: rgba(238, 238, 238, 0.7);
}
.ribbed-grayLighter {
  background: #eeeeee linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-grayLighter:before {
  background: #eeeeee !important;
}
.before-fg-grayLighter:before {
  color: #eeeeee !important;
}
.after-bg-grayLighter:after {
  background: #eeeeee !important;
}
.after-fg-grayLighter:after {
  color: #eeeeee !important;
}
.bg-hover-grayLighter:hover {
  background: #eeeeee !important;
}
.bg-active-grayLighter:active {
  background: #eeeeee !important;
}
.bg-focus-grayLighter:focus {
  background: #eeeeee !important;
}
.fg-hover-grayLighter:hover {
  color: #eeeeee !important;
}
.fg-active-grayLighter:active {
  color: #eeeeee !important;
}
.fg-focus-grayLighter:focus {
  color: #eeeeee !important;
}
.bd-lightGray {
  border-color: #999999 !important;
}
.ol-lightGray {
  outline-color: #999999 !important;
}
.op-lightGray {
  background-color: rgba(153, 153, 153, 0.7);
}
.ribbed-lightGray {
  background: #999999 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lightGray:before {
  background: #999999 !important;
}
.before-fg-lightGray:before {
  color: #999999 !important;
}
.after-bg-lightGray:after {
  background: #999999 !important;
}
.after-fg-lightGray:after {
  color: #999999 !important;
}
.bg-hover-lightGray:hover {
  background: #999999 !important;
}
.bg-active-lightGray:active {
  background: #999999 !important;
}
.bg-focus-lightGray:focus {
  background: #999999 !important;
}
.fg-hover-lightGray:hover {
  color: #999999 !important;
}
.fg-active-lightGray:active {
  color: #999999 !important;
}
.fg-focus-lightGray:focus {
  color: #999999 !important;
}
.bd-lighterGray {
  border-color: #eeeeee !important;
}
.ol-lighterGray {
  outline-color: #eeeeee !important;
}
.op-lighterGray {
  background-color: rgba(238, 238, 238, 0.7);
}
.ribbed-lighterGray {
  background: #eeeeee linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-lighterGray:before {
  background: #eeeeee !important;
}
.before-fg-lighterGray:before {
  color: #eeeeee !important;
}
.after-bg-lighterGray:after {
  background: #eeeeee !important;
}
.after-fg-lighterGray:after {
  color: #eeeeee !important;
}
.bg-hover-lighterGray:hover {
  background: #eeeeee !important;
}
.bg-active-lighterGray:active {
  background: #eeeeee !important;
}
.bg-focus-lighterGray:focus {
  background: #eeeeee !important;
}
.fg-hover-lighterGray:hover {
  color: #eeeeee !important;
}
.fg-active-lighterGray:active {
  color: #eeeeee !important;
}
.fg-focus-lighterGray:focus {
  color: #eeeeee !important;
}
.bd-darkGray {
  border-color: #333333 !important;
}
.ol-darkGray {
  outline-color: #333333 !important;
}
.op-darkGray {
  background-color: rgba(51, 51, 51, 0.7);
}
.ribbed-darkGray {
  background: #333333 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkGray:before {
  background: #333333 !important;
}
.before-fg-darkGray:before {
  color: #333333 !important;
}
.after-bg-darkGray:after {
  background: #333333 !important;
}
.after-fg-darkGray:after {
  color: #333333 !important;
}
.bg-hover-darkGray:hover {
  background: #333333 !important;
}
.bg-active-darkGray:active {
  background: #333333 !important;
}
.bg-focus-darkGray:focus {
  background: #333333 !important;
}
.fg-hover-darkGray:hover {
  color: #333333 !important;
}
.fg-active-darkGray:active {
  color: #333333 !important;
}
.fg-focus-darkGray:focus {
  color: #333333 !important;
}
.bd-darkerGray {
  border-color: #222222 !important;
}
.ol-darkerGray {
  outline-color: #222222 !important;
}
.op-darkerGray {
  background-color: rgba(34, 34, 34, 0.7);
}
.ribbed-darkerGray {
  background: #222222 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darkerGray:before {
  background: #222222 !important;
}
.before-fg-darkerGray:before {
  color: #222222 !important;
}
.after-bg-darkerGray:after {
  background: #222222 !important;
}
.after-fg-darkerGray:after {
  color: #222222 !important;
}
.bg-hover-darkerGray:hover {
  background: #222222 !important;
}
.bg-active-darkerGray:active {
  background: #222222 !important;
}
.bg-focus-darkerGray:focus {
  background: #222222 !important;
}
.fg-hover-darkerGray:hover {
  color: #222222 !important;
}
.fg-active-darkerGray:active {
  color: #222222 !important;
}
.fg-focus-darkerGray:focus {
  color: #222222 !important;
}
.bd-darker {
  border-color: #222222 !important;
}
.ol-darker {
  outline-color: #222222 !important;
}
.op-darker {
  background-color: rgba(34, 34, 34, 0.7);
}
.ribbed-darker {
  background: #222222 linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
}
.before-bg-darker:before {
  background: #222222 !important;
}
.before-fg-darker:before {
  color: #222222 !important;
}
.after-bg-darker:after {
  background: #222222 !important;
}
.after-fg-darker:after {
  color: #222222 !important;
}
.bg-hover-darker:hover {
  background: #222222 !important;
}
.bg-active-darker:active {
  background: #222222 !important;
}
.bg-focus-darker:focus {
  background: #222222 !important;
}
.fg-hover-darker:hover {
  color: #222222 !important;
}
.fg-active-darker:active {
  color: #222222 !important;
}
.fg-focus-darker:focus {
  color: #222222 !important;
}
