/*
* params: name, background, backgroundHover, item, itemHover, itemDisabled, divider, dropdown-toggle
*/
.app-bar.pink {
  background-color: #dc4fad;
}
.app-bar.pink .app-bar-element:hover,
.app-bar.pink .app-bar-menu > li:hover,
.app-bar.pink .app-bar-menu > li > a:hover,
.app-bar.pink .app-bar-element:active,
.app-bar.pink .app-bar-menu > li:active,
.app-bar.pink .app-bar-menu > li > a:active {
  background-color: #9a165a;
}
.app-bar.pink .app-bar-divider {
  background-color: #f472d0;
}
.app-bar.pink .app-bar-element .d-menu,
.app-bar.pink .app-bar-menu > li .d-menu {
  background-color: #dc4fad;
  border-color: transparent;
}
.app-bar.pink .app-bar-element .d-menu li:not(.disabled) > a,
.app-bar.pink .app-bar-menu > li .d-menu li:not(.disabled) > a,
.app-bar.pink .app-bar-element .d-menu li > a,
.app-bar.pink .app-bar-menu > li .d-menu li > a {
  background-color: #dc4fad;
  color: #ffffff;
}
.app-bar.pink .app-bar-element .d-menu li:not(.disabled) > a:hover,
.app-bar.pink .app-bar-menu > li .d-menu li:not(.disabled) > a:hover,
.app-bar.pink .app-bar-element .d-menu li > a:hover,
.app-bar.pink .app-bar-menu > li .d-menu li > a:hover {
  background-color: #9a165a;
  color: #ffffff;
}
.app-bar.pink .app-bar-element .d-menu li:hover > a,
.app-bar.pink .app-bar-menu > li .d-menu li:hover > a {
  background-color: #9a165a;
}
.app-bar.pink .app-bar-element .d-menu .divider,
.app-bar.pink .app-bar-menu > li .d-menu .divider {
  background-color: #f472d0;
}
.app-bar.pink .app-bar-element .d-menu .divider:hover,
.app-bar.pink .app-bar-menu > li .d-menu .divider:hover {
  background-color: #f472d0;
}
.app-bar.pink .app-bar-element .dropdown-toggle:before,
.app-bar.pink .app-bar-menu li .dropdown-toggle:before {
  border-color: #ffffff;
}
.app-bar.pink .app-bar-element.disabled,
.app-bar.pink .app-bar-menu li.disabled,
.app-bar.pink .app-bar-element:disabled,
.app-bar.pink .app-bar-menu li:disabled {
  color: #555555;
}
.app-bar.pink .app-bar-element.disabled a,
.app-bar.pink .app-bar-menu li.disabled a,
.app-bar.pink .app-bar-element:disabled a,
.app-bar.pink .app-bar-menu li:disabled a {
  color: inherit !important;
}
.app-bar.pink .app-bar-pullbutton:hover {
  background-color: #9a165a;
}
.app-bar.pink .app-bar-drop-container {
  border: 2px solid #dc4fad;
  background-color: #dc4fad;
  color: #ffffff;
}
.app-bar.pink .app-bar-drop-container:before {
  background-color: #dc4fad;
  border-color: #dc4fad;
}
.app-bar.pink .app-bar-pullmenu .app-bar-menu {
  border-top: 1px solid #f472d0;
  background-color: #f472d0;
}
.app-bar.pink .app-bar-pullmenu .app-bar-menu li:hover {
  background-color: #dc4fad;
}
.app-bar.pink .app-bar-pullmenu .app-bar-menu li:hover a {
  background-color: #9a165a;
  color: #ffffff;
}
.app-bar.darcula {
  background-color: #3C3F41;
}
.app-bar.darcula .app-bar-element:hover,
.app-bar.darcula .app-bar-menu > li:hover,
.app-bar.darcula .app-bar-menu > li > a:hover,
.app-bar.darcula .app-bar-element:active,
.app-bar.darcula .app-bar-menu > li:active,
.app-bar.darcula .app-bar-menu > li > a:active {
  background-color: #1d1d1d;
}
.app-bar.darcula .app-bar-divider {
  background-color: #616162;
}
.app-bar.darcula .app-bar-element .d-menu,
.app-bar.darcula .app-bar-menu > li .d-menu {
  background-color: #3C3F41;
  border-color: transparent;
}
.app-bar.darcula .app-bar-element .d-menu li:not(.disabled) > a,
.app-bar.darcula .app-bar-menu > li .d-menu li:not(.disabled) > a,
.app-bar.darcula .app-bar-element .d-menu li > a,
.app-bar.darcula .app-bar-menu > li .d-menu li > a {
  background-color: #3C3F41;
  color: #ffffff;
}
.app-bar.darcula .app-bar-element .d-menu li:not(.disabled) > a:hover,
.app-bar.darcula .app-bar-menu > li .d-menu li:not(.disabled) > a:hover,
.app-bar.darcula .app-bar-element .d-menu li > a:hover,
.app-bar.darcula .app-bar-menu > li .d-menu li > a:hover {
  background-color: #1d1d1d;
  color: #ffffff;
}
.app-bar.darcula .app-bar-element .d-menu li:hover > a,
.app-bar.darcula .app-bar-menu > li .d-menu li:hover > a {
  background-color: #1d1d1d;
}
.app-bar.darcula .app-bar-element .d-menu .divider,
.app-bar.darcula .app-bar-menu > li .d-menu .divider {
  background-color: #616162;
}
.app-bar.darcula .app-bar-element .d-menu .divider:hover,
.app-bar.darcula .app-bar-menu > li .d-menu .divider:hover {
  background-color: #616162;
}
.app-bar.darcula .app-bar-element .dropdown-toggle:before,
.app-bar.darcula .app-bar-menu li .dropdown-toggle:before {
  border-color: #ffffff;
}
.app-bar.darcula .app-bar-element.disabled,
.app-bar.darcula .app-bar-menu li.disabled,
.app-bar.darcula .app-bar-element:disabled,
.app-bar.darcula .app-bar-menu li:disabled {
  color: #555555;
}
.app-bar.darcula .app-bar-element.disabled a,
.app-bar.darcula .app-bar-menu li.disabled a,
.app-bar.darcula .app-bar-element:disabled a,
.app-bar.darcula .app-bar-menu li:disabled a {
  color: inherit !important;
}
.app-bar.darcula .app-bar-pullbutton:hover {
  background-color: #1d1d1d;
}
.app-bar.darcula .app-bar-drop-container {
  border: 2px solid #3C3F41;
  background-color: #3C3F41;
  color: #ffffff;
}
.app-bar.darcula .app-bar-drop-container:before {
  background-color: #3C3F41;
  border-color: #3C3F41;
}
.app-bar.darcula .app-bar-pullmenu .app-bar-menu {
  border-top: 1px solid #616162;
  background-color: #616162;
}
.app-bar.darcula .app-bar-pullmenu .app-bar-menu li:hover {
  background-color: #3C3F41;
}
.app-bar.darcula .app-bar-pullmenu .app-bar-menu li:hover a {
  background-color: #1d1d1d;
  color: #ffffff;
}
.app-bar.navy {
  background-color: #0072C6;
}
.app-bar.navy .app-bar-element:hover,
.app-bar.navy .app-bar-menu > li:hover,
.app-bar.navy .app-bar-menu > li > a:hover,
.app-bar.navy .app-bar-element:active,
.app-bar.navy .app-bar-menu > li:active,
.app-bar.navy .app-bar-menu > li > a:active {
  background-color: #005696;
}
.app-bar.navy .app-bar-divider {
  background-color: #4C9CD7;
}
.app-bar.navy .app-bar-element .d-menu,
.app-bar.navy .app-bar-menu > li .d-menu {
  background-color: #0072C6;
  border-color: transparent;
}
.app-bar.navy .app-bar-element .d-menu li:not(.disabled) > a,
.app-bar.navy .app-bar-menu > li .d-menu li:not(.disabled) > a,
.app-bar.navy .app-bar-element .d-menu li > a,
.app-bar.navy .app-bar-menu > li .d-menu li > a {
  background-color: #0072C6;
  color: #ffffff;
}
.app-bar.navy .app-bar-element .d-menu li:not(.disabled) > a:hover,
.app-bar.navy .app-bar-menu > li .d-menu li:not(.disabled) > a:hover,
.app-bar.navy .app-bar-element .d-menu li > a:hover,
.app-bar.navy .app-bar-menu > li .d-menu li > a:hover {
  background-color: #005696;
  color: #ffffff;
}
.app-bar.navy .app-bar-element .d-menu li:hover > a,
.app-bar.navy .app-bar-menu > li .d-menu li:hover > a {
  background-color: #005696;
}
.app-bar.navy .app-bar-element .d-menu .divider,
.app-bar.navy .app-bar-menu > li .d-menu .divider {
  background-color: #4C9CD7;
}
.app-bar.navy .app-bar-element .d-menu .divider:hover,
.app-bar.navy .app-bar-menu > li .d-menu .divider:hover {
  background-color: #4C9CD7;
}
.app-bar.navy .app-bar-element .dropdown-toggle:before,
.app-bar.navy .app-bar-menu li .dropdown-toggle:before {
  border-color: #ffffff;
}
.app-bar.navy .app-bar-element.disabled,
.app-bar.navy .app-bar-menu li.disabled,
.app-bar.navy .app-bar-element:disabled,
.app-bar.navy .app-bar-menu li:disabled {
  color: #555555;
}
.app-bar.navy .app-bar-element.disabled a,
.app-bar.navy .app-bar-menu li.disabled a,
.app-bar.navy .app-bar-element:disabled a,
.app-bar.navy .app-bar-menu li:disabled a {
  color: inherit !important;
}
.app-bar.navy .app-bar-pullbutton:hover {
  background-color: #005696;
}
.app-bar.navy .app-bar-drop-container {
  border: 2px solid #0072C6;
  background-color: #0072C6;
  color: #ffffff;
}
.app-bar.navy .app-bar-drop-container:before {
  background-color: #0072C6;
  border-color: #0072C6;
}
.app-bar.navy .app-bar-pullmenu .app-bar-menu {
  border-top: 1px solid #4C9CD7;
  background-color: #4C9CD7;
}
.app-bar.navy .app-bar-pullmenu .app-bar-menu li:hover {
  background-color: #0072C6;
}
.app-bar.navy .app-bar-pullmenu .app-bar-menu li:hover a {
  background-color: #005696;
  color: #ffffff;
}
.app-bar.red {
  background-color: #CE352C;
}
.app-bar.red .app-bar-element:hover,
.app-bar.red .app-bar-menu > li:hover,
.app-bar.red .app-bar-menu > li > a:hover,
.app-bar.red .app-bar-element:active,
.app-bar.red .app-bar-menu > li:active,
.app-bar.red .app-bar-menu > li > a:active {
  background-color: #9a1616;
}
.app-bar.red .app-bar-divider {
  background-color: #da5a53;
}
.app-bar.red .app-bar-element .d-menu,
.app-bar.red .app-bar-menu > li .d-menu {
  background-color: #CE352C;
  border-color: transparent;
}
.app-bar.red .app-bar-element .d-menu li:not(.disabled) > a,
.app-bar.red .app-bar-menu > li .d-menu li:not(.disabled) > a,
.app-bar.red .app-bar-element .d-menu li > a,
.app-bar.red .app-bar-menu > li .d-menu li > a {
  background-color: #CE352C;
  color: #ffffff;
}
.app-bar.red .app-bar-element .d-menu li:not(.disabled) > a:hover,
.app-bar.red .app-bar-menu > li .d-menu li:not(.disabled) > a:hover,
.app-bar.red .app-bar-element .d-menu li > a:hover,
.app-bar.red .app-bar-menu > li .d-menu li > a:hover {
  background-color: #9a1616;
  color: #ffffff;
}
.app-bar.red .app-bar-element .d-menu li:hover > a,
.app-bar.red .app-bar-menu > li .d-menu li:hover > a {
  background-color: #9a1616;
}
.app-bar.red .app-bar-element .d-menu .divider,
.app-bar.red .app-bar-menu > li .d-menu .divider {
  background-color: #da5a53;
}
.app-bar.red .app-bar-element .d-menu .divider:hover,
.app-bar.red .app-bar-menu > li .d-menu .divider:hover {
  background-color: #da5a53;
}
.app-bar.red .app-bar-element .dropdown-toggle:before,
.app-bar.red .app-bar-menu li .dropdown-toggle:before {
  border-color: #ffffff;
}
.app-bar.red .app-bar-element.disabled,
.app-bar.red .app-bar-menu li.disabled,
.app-bar.red .app-bar-element:disabled,
.app-bar.red .app-bar-menu li:disabled {
  color: #555555;
}
.app-bar.red .app-bar-element.disabled a,
.app-bar.red .app-bar-menu li.disabled a,
.app-bar.red .app-bar-element:disabled a,
.app-bar.red .app-bar-menu li:disabled a {
  color: inherit !important;
}
.app-bar.red .app-bar-pullbutton:hover {
  background-color: #9a1616;
}
.app-bar.red .app-bar-drop-container {
  border: 2px solid #CE352C;
  background-color: #CE352C;
  color: #ffffff;
}
.app-bar.red .app-bar-drop-container:before {
  background-color: #CE352C;
  border-color: #CE352C;
}
.app-bar.red .app-bar-pullmenu .app-bar-menu {
  border-top: 1px solid #da5a53;
  background-color: #da5a53;
}
.app-bar.red .app-bar-pullmenu .app-bar-menu li:hover {
  background-color: #CE352C;
}
.app-bar.red .app-bar-pullmenu .app-bar-menu li:hover a {
  background-color: #9a1616;
  color: #ffffff;
}
.app-bar.green {
  background-color: #60a917;
}
.app-bar.green .app-bar-element:hover,
.app-bar.green .app-bar-menu > li:hover,
.app-bar.green .app-bar-menu > li > a:hover,
.app-bar.green .app-bar-element:active,
.app-bar.green .app-bar-menu > li:active,
.app-bar.green .app-bar-menu > li > a:active {
  background-color: #128023;
}
.app-bar.green .app-bar-divider {
  background-color: #7ad61d;
}
.app-bar.green .app-bar-element .d-menu,
.app-bar.green .app-bar-menu > li .d-menu {
  background-color: #60a917;
  border-color: transparent;
}
.app-bar.green .app-bar-element .d-menu li:not(.disabled) > a,
.app-bar.green .app-bar-menu > li .d-menu li:not(.disabled) > a,
.app-bar.green .app-bar-element .d-menu li > a,
.app-bar.green .app-bar-menu > li .d-menu li > a {
  background-color: #60a917;
  color: #ffffff;
}
.app-bar.green .app-bar-element .d-menu li:not(.disabled) > a:hover,
.app-bar.green .app-bar-menu > li .d-menu li:not(.disabled) > a:hover,
.app-bar.green .app-bar-element .d-menu li > a:hover,
.app-bar.green .app-bar-menu > li .d-menu li > a:hover {
  background-color: #128023;
  color: #ffffff;
}
.app-bar.green .app-bar-element .d-menu li:hover > a,
.app-bar.green .app-bar-menu > li .d-menu li:hover > a {
  background-color: #128023;
}
.app-bar.green .app-bar-element .d-menu .divider,
.app-bar.green .app-bar-menu > li .d-menu .divider {
  background-color: #7ad61d;
}
.app-bar.green .app-bar-element .d-menu .divider:hover,
.app-bar.green .app-bar-menu > li .d-menu .divider:hover {
  background-color: #7ad61d;
}
.app-bar.green .app-bar-element .dropdown-toggle:before,
.app-bar.green .app-bar-menu li .dropdown-toggle:before {
  border-color: #ffffff;
}
.app-bar.green .app-bar-element.disabled,
.app-bar.green .app-bar-menu li.disabled,
.app-bar.green .app-bar-element:disabled,
.app-bar.green .app-bar-menu li:disabled {
  color: #555555;
}
.app-bar.green .app-bar-element.disabled a,
.app-bar.green .app-bar-menu li.disabled a,
.app-bar.green .app-bar-element:disabled a,
.app-bar.green .app-bar-menu li:disabled a {
  color: inherit !important;
}
.app-bar.green .app-bar-pullbutton:hover {
  background-color: #128023;
}
.app-bar.green .app-bar-drop-container {
  border: 2px solid #60a917;
  background-color: #60a917;
  color: #ffffff;
}
.app-bar.green .app-bar-drop-container:before {
  background-color: #60a917;
  border-color: #60a917;
}
.app-bar.green .app-bar-pullmenu .app-bar-menu {
  border-top: 1px solid #7ad61d;
  background-color: #7ad61d;
}
.app-bar.green .app-bar-pullmenu .app-bar-menu li:hover {
  background-color: #60a917;
}
.app-bar.green .app-bar-pullmenu .app-bar-menu li:hover a {
  background-color: #128023;
  color: #ffffff;
}
.app-bar.orange {
  background-color: #fa6800;
}
.app-bar.orange .app-bar-element:hover,
.app-bar.orange .app-bar-menu > li:hover,
.app-bar.orange .app-bar-menu > li > a:hover,
.app-bar.orange .app-bar-element:active,
.app-bar.orange .app-bar-menu > li:active,
.app-bar.orange .app-bar-menu > li > a:active {
  background-color: #bf5a15;
}
.app-bar.orange .app-bar-divider {
  background-color: #ffc194;
}
.app-bar.orange .app-bar-element .d-menu,
.app-bar.orange .app-bar-menu > li .d-menu {
  background-color: #fa6800;
  border-color: transparent;
}
.app-bar.orange .app-bar-element .d-menu li:not(.disabled) > a,
.app-bar.orange .app-bar-menu > li .d-menu li:not(.disabled) > a,
.app-bar.orange .app-bar-element .d-menu li > a,
.app-bar.orange .app-bar-menu > li .d-menu li > a {
  background-color: #fa6800;
  color: #ffffff;
}
.app-bar.orange .app-bar-element .d-menu li:not(.disabled) > a:hover,
.app-bar.orange .app-bar-menu > li .d-menu li:not(.disabled) > a:hover,
.app-bar.orange .app-bar-element .d-menu li > a:hover,
.app-bar.orange .app-bar-menu > li .d-menu li > a:hover {
  background-color: #bf5a15;
  color: #ffffff;
}
.app-bar.orange .app-bar-element .d-menu li:hover > a,
.app-bar.orange .app-bar-menu > li .d-menu li:hover > a {
  background-color: #bf5a15;
}
.app-bar.orange .app-bar-element .d-menu .divider,
.app-bar.orange .app-bar-menu > li .d-menu .divider {
  background-color: #ffc194;
}
.app-bar.orange .app-bar-element .d-menu .divider:hover,
.app-bar.orange .app-bar-menu > li .d-menu .divider:hover {
  background-color: #ffc194;
}
.app-bar.orange .app-bar-element .dropdown-toggle:before,
.app-bar.orange .app-bar-menu li .dropdown-toggle:before {
  border-color: #ffffff;
}
.app-bar.orange .app-bar-element.disabled,
.app-bar.orange .app-bar-menu li.disabled,
.app-bar.orange .app-bar-element:disabled,
.app-bar.orange .app-bar-menu li:disabled {
  color: #555555;
}
.app-bar.orange .app-bar-element.disabled a,
.app-bar.orange .app-bar-menu li.disabled a,
.app-bar.orange .app-bar-element:disabled a,
.app-bar.orange .app-bar-menu li:disabled a {
  color: inherit !important;
}
.app-bar.orange .app-bar-pullbutton:hover {
  background-color: #bf5a15;
}
.app-bar.orange .app-bar-drop-container {
  border: 2px solid #fa6800;
  background-color: #fa6800;
  color: #ffffff;
}
.app-bar.orange .app-bar-drop-container:before {
  background-color: #fa6800;
  border-color: #fa6800;
}
.app-bar.orange .app-bar-pullmenu .app-bar-menu {
  border-top: 1px solid #ffc194;
  background-color: #ffc194;
}
.app-bar.orange .app-bar-pullmenu .app-bar-menu li:hover {
  background-color: #fa6800;
}
.app-bar.orange .app-bar-pullmenu .app-bar-menu li:hover a {
  background-color: #bf5a15;
  color: #ffffff;
}
/*
* params: name, background, backgroundHover, item, itemHover, itemDisabled, divider, dropdown-toggle
*/
.v-menu.darcula,
.d-menu.darcula {
  background: #3C3F41;
}
.v-menu.darcula li > a,
.d-menu.darcula li > a {
  background: #3C3F41;
  color: #ffffff;
}
.v-menu.darcula li > a .icon,
.d-menu.darcula li > a .icon {
  color: #cccccc;
}
.v-menu.darcula li > a:hover,
.d-menu.darcula li > a:hover {
  background: #1d1d1d;
  color: #ffffff;
}
.v-menu.darcula li > a:hover .icon,
.d-menu.darcula li > a:hover .icon {
  color: #ffffff;
}
.v-menu.darcula li > .item-block,
.d-menu.darcula li > .item-block {
  background: #484c4e;
}
.v-menu.darcula li.disabled > a,
.d-menu.darcula li.disabled > a {
  background: #3C3F41;
  color: #555555;
}
.v-menu.darcula li > a.dropdown-toggle:before,
.d-menu.darcula li > a.dropdown-toggle:before {
  border-color: #ffffff;
}
.v-menu.darcula li.menu-title,
.d-menu.darcula li.menu-title {
  background: #303234;
  color: #cccccc;
}
.v-menu.darcula li.divider,
.d-menu.darcula li.divider {
  background: #616162;
}
.v-menu.pink,
.d-menu.pink {
  background: #dc4fad;
}
.v-menu.pink li > a,
.d-menu.pink li > a {
  background: #dc4fad;
  color: #ffffff;
}
.v-menu.pink li > a .icon,
.d-menu.pink li > a .icon {
  color: #cccccc;
}
.v-menu.pink li > a:hover,
.d-menu.pink li > a:hover {
  background: #9a165a;
  color: #ffffff;
}
.v-menu.pink li > a:hover .icon,
.d-menu.pink li > a:hover .icon {
  color: #ffffff;
}
.v-menu.pink li > .item-block,
.d-menu.pink li > .item-block {
  background: #e064b7;
}
.v-menu.pink li.disabled > a,
.d-menu.pink li.disabled > a {
  background: #dc4fad;
  color: #555555;
}
.v-menu.pink li > a.dropdown-toggle:before,
.d-menu.pink li > a.dropdown-toggle:before {
  border-color: #ffffff;
}
.v-menu.pink li.menu-title,
.d-menu.pink li.menu-title {
  background: #d83aa3;
  color: #cccccc;
}
.v-menu.pink li.divider,
.d-menu.pink li.divider {
  background: #f472d0;
}
.v-menu.navy,
.d-menu.navy {
  background: #0072C6;
}
.v-menu.navy li > a,
.d-menu.navy li > a {
  background: #0072C6;
  color: #ffffff;
}
.v-menu.navy li > a .icon,
.d-menu.navy li > a .icon {
  color: #cccccc;
}
.v-menu.navy li > a:hover,
.d-menu.navy li > a:hover {
  background: #005696;
  color: #ffffff;
}
.v-menu.navy li > a:hover .icon,
.d-menu.navy li > a:hover .icon {
  color: #ffffff;
}
.v-menu.navy li > .item-block,
.d-menu.navy li > .item-block {
  background: #0081e0;
}
.v-menu.navy li.disabled > a,
.d-menu.navy li.disabled > a {
  background: #0072C6;
  color: #555555;
}
.v-menu.navy li > a.dropdown-toggle:before,
.d-menu.navy li > a.dropdown-toggle:before {
  border-color: #ffffff;
}
.v-menu.navy li.menu-title,
.d-menu.navy li.menu-title {
  background: #0063ad;
  color: #cccccc;
}
.v-menu.navy li.divider,
.d-menu.navy li.divider {
  background: #4C9CD7;
}
.v-menu.red,
.d-menu.red {
  background: #CE352C;
}
.v-menu.red li > a,
.d-menu.red li > a {
  background: #CE352C;
  color: #ffffff;
}
.v-menu.red li > a .icon,
.d-menu.red li > a .icon {
  color: #cccccc;
}
.v-menu.red li > a:hover,
.d-menu.red li > a:hover {
  background: #9a1616;
  color: #ffffff;
}
.v-menu.red li > a:hover .icon,
.d-menu.red li > a:hover .icon {
  color: #ffffff;
}
.v-menu.red li > .item-block,
.d-menu.red li > .item-block {
  background: #d6463e;
}
.v-menu.red li.disabled > a,
.d-menu.red li.disabled > a {
  background: #CE352C;
  color: #555555;
}
.v-menu.red li > a.dropdown-toggle:before,
.d-menu.red li > a.dropdown-toggle:before {
  border-color: #ffffff;
}
.v-menu.red li.menu-title,
.d-menu.red li.menu-title {
  background: #b93028;
  color: #cccccc;
}
.v-menu.red li.divider,
.d-menu.red li.divider {
  background: #da5a53;
}
.v-menu.green,
.d-menu.green {
  background: #60a917;
}
.v-menu.green li > a,
.d-menu.green li > a {
  background: #60a917;
  color: #ffffff;
}
.v-menu.green li > a .icon,
.d-menu.green li > a .icon {
  color: #cccccc;
}
.v-menu.green li > a:hover,
.d-menu.green li > a:hover {
  background: #128023;
  color: #ffffff;
}
.v-menu.green li > a:hover .icon,
.d-menu.green li > a:hover .icon {
  color: #ffffff;
}
.v-menu.green li > .item-block,
.d-menu.green li > .item-block {
  background: #6dbf1a;
}
.v-menu.green li.disabled > a,
.d-menu.green li.disabled > a {
  background: #60a917;
  color: #555555;
}
.v-menu.green li > a.dropdown-toggle:before,
.d-menu.green li > a.dropdown-toggle:before {
  border-color: #ffffff;
}
.v-menu.green li.menu-title,
.d-menu.green li.menu-title {
  background: #539314;
  color: #cccccc;
}
.v-menu.green li.divider,
.d-menu.green li.divider {
  background: #7ad61d;
}
.v-menu.orange,
.d-menu.orange {
  background: #fa6800;
}
.v-menu.orange li > a,
.d-menu.orange li > a {
  background: #fa6800;
  color: #ffffff;
}
.v-menu.orange li > a .icon,
.d-menu.orange li > a .icon {
  color: #cccccc;
}
.v-menu.orange li > a:hover,
.d-menu.orange li > a:hover {
  background: #bf5a15;
  color: #ffffff;
}
.v-menu.orange li > a:hover .icon,
.d-menu.orange li > a:hover .icon {
  color: #ffffff;
}
.v-menu.orange li > .item-block,
.d-menu.orange li > .item-block {
  background: #ff7615;
}
.v-menu.orange li.disabled > a,
.d-menu.orange li.disabled > a {
  background: #fa6800;
  color: #555555;
}
.v-menu.orange li > a.dropdown-toggle:before,
.d-menu.orange li > a.dropdown-toggle:before {
  border-color: #ffffff;
}
.v-menu.orange li.menu-title,
.d-menu.orange li.menu-title {
  background: #e15d00;
  color: #cccccc;
}
.v-menu.orange li.divider,
.d-menu.orange li.divider {
  background: #ffc194;
}
/*
* params: name, background, backgroundHover, item, itemHover, itemDisabled, divider, dropdown-toggle
*/
.t-menu.darcula {
  background-color: #3C3F41;
}
.t-menu.darcula .t-menu {
  background-color: #3C3F41;
}
.t-menu.darcula li > a {
  background: #3C3F41;
  color: #ffffff;
  border-bottom-color: #616162;
}
.t-menu.darcula li > a.dropdown-toggle:after,
.t-menu.darcula li > a.dropdown-toggle:hover:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.darcula li:hover > a {
  background: #1d1d1d;
  color: #ffffff;
}
.t-menu.darcula li:hover > a.dropdown-toggle:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.darcula li.disabled > a {
  background: #3C3F41;
  color: #555555;
}
.t-menu.darcula li.disabled > a:hover {
  background: #3C3F41;
}
.t-menu.darcula.horizontal > li > a,
.t-menu.darcula .horizontal > li > a {
  border-right-color: #616162;
}
.t-menu.pink {
  background-color: #dc4fad;
}
.t-menu.pink .t-menu {
  background-color: #dc4fad;
}
.t-menu.pink li > a {
  background: #dc4fad;
  color: #ffffff;
  border-bottom-color: #f472d0;
}
.t-menu.pink li > a.dropdown-toggle:after,
.t-menu.pink li > a.dropdown-toggle:hover:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.pink li:hover > a {
  background: #9a165a;
  color: #ffffff;
}
.t-menu.pink li:hover > a.dropdown-toggle:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.pink li.disabled > a {
  background: #dc4fad;
  color: #555555;
}
.t-menu.pink li.disabled > a:hover {
  background: #dc4fad;
}
.t-menu.pink.horizontal > li > a,
.t-menu.pink .horizontal > li > a {
  border-right-color: #f472d0;
}
.t-menu.navy {
  background-color: #0072C6;
}
.t-menu.navy .t-menu {
  background-color: #0072C6;
}
.t-menu.navy li > a {
  background: #0072C6;
  color: #ffffff;
  border-bottom-color: #4C9CD7;
}
.t-menu.navy li > a.dropdown-toggle:after,
.t-menu.navy li > a.dropdown-toggle:hover:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.navy li:hover > a {
  background: #005696;
  color: #ffffff;
}
.t-menu.navy li:hover > a.dropdown-toggle:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.navy li.disabled > a {
  background: #0072C6;
  color: #555555;
}
.t-menu.navy li.disabled > a:hover {
  background: #0072C6;
}
.t-menu.navy.horizontal > li > a,
.t-menu.navy .horizontal > li > a {
  border-right-color: #4C9CD7;
}
.t-menu.red {
  background-color: #CE352C;
}
.t-menu.red .t-menu {
  background-color: #CE352C;
}
.t-menu.red li > a {
  background: #CE352C;
  color: #ffffff;
  border-bottom-color: #da5a53;
}
.t-menu.red li > a.dropdown-toggle:after,
.t-menu.red li > a.dropdown-toggle:hover:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.red li:hover > a {
  background: #9a1616;
  color: #ffffff;
}
.t-menu.red li:hover > a.dropdown-toggle:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.red li.disabled > a {
  background: #CE352C;
  color: #555555;
}
.t-menu.red li.disabled > a:hover {
  background: #CE352C;
}
.t-menu.red.horizontal > li > a,
.t-menu.red .horizontal > li > a {
  border-right-color: #da5a53;
}
.t-menu.green {
  background-color: #60a917;
}
.t-menu.green .t-menu {
  background-color: #60a917;
}
.t-menu.green li > a {
  background: #60a917;
  color: #ffffff;
  border-bottom-color: #7ad61d;
}
.t-menu.green li > a.dropdown-toggle:after,
.t-menu.green li > a.dropdown-toggle:hover:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.green li:hover > a {
  background: #128023;
  color: #ffffff;
}
.t-menu.green li:hover > a.dropdown-toggle:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.green li.disabled > a {
  background: #60a917;
  color: #555555;
}
.t-menu.green li.disabled > a:hover {
  background: #60a917;
}
.t-menu.green.horizontal > li > a,
.t-menu.green .horizontal > li > a {
  border-right-color: #7ad61d;
}
.t-menu.orange {
  background-color: #fa6800;
}
.t-menu.orange .t-menu {
  background-color: #fa6800;
}
.t-menu.orange li > a {
  background: #fa6800;
  color: #ffffff;
  border-bottom-color: #ffc194;
}
.t-menu.orange li > a.dropdown-toggle:after,
.t-menu.orange li > a.dropdown-toggle:hover:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.orange li:hover > a {
  background: #bf5a15;
  color: #ffffff;
}
.t-menu.orange li:hover > a.dropdown-toggle:after {
  border-color: transparent transparent #ffffff;
}
.t-menu.orange li.disabled > a {
  background: #fa6800;
  color: #555555;
}
.t-menu.orange li.disabled > a:hover {
  background: #fa6800;
}
.t-menu.orange.horizontal > li > a,
.t-menu.orange .horizontal > li > a {
  border-right-color: #ffc194;
}
/*
* params: name, background, backgroundHover, backgroundActive, item, itemHover, itemActive, itemDisabled
*/
.sidebar.darcula {
  background: #3C3F41;
}
.sidebar.darcula li > a {
  background: #3C3F41;
  color: #ffffff;
}
.sidebar.darcula li:hover > a {
  background: #4d5154;
  color: #ffffff;
}
.sidebar.darcula li.active > a {
  background: #1d1d1d;
  color: #ffffff;
}
.sidebar.darcula li.disabled > a {
  background: #3C3F41;
  color: #555555;
}
.sidebar.pink {
  background: #dc4fad;
}
.sidebar.pink li > a {
  background: #dc4fad;
  color: #ffffff;
}
.sidebar.pink li:hover > a {
  background: #b91a6c;
  color: #ffffff;
}
.sidebar.pink li.active > a {
  background: #9a165a;
  color: #ffffff;
}
.sidebar.pink li.disabled > a {
  background: #dc4fad;
  color: #555555;
}
.sidebar.navy {
  background: #0072C6;
}
.sidebar.navy li > a {
  background: #0072C6;
  color: #ffffff;
}
.sidebar.navy li:hover > a {
  background: #006aba;
  color: #ffffff;
}
.sidebar.navy li.active > a {
  background: #005696;
  color: #ffffff;
}
.sidebar.navy li.disabled > a {
  background: #0072C6;
  color: #555555;
}
.sidebar.red {
  background: #CE352C;
}
.sidebar.red li > a {
  background: #CE352C;
  color: #ffffff;
}
.sidebar.red li:hover > a {
  background: #b91a1a;
  color: #ffffff;
}
.sidebar.red li.active > a {
  background: #9a1616;
  color: #ffffff;
}
.sidebar.red li.disabled > a {
  background: #CE352C;
  color: #555555;
}
.sidebar.green {
  background: #60a917;
}
.sidebar.green li > a {
  background: #60a917;
  color: #ffffff;
}
.sidebar.green li:hover > a {
  background: #169f2c;
  color: #ffffff;
}
.sidebar.green li.active > a {
  background: #128023;
  color: #ffffff;
}
.sidebar.green li.disabled > a {
  background: #60a917;
  color: #555555;
}
.sidebar.orange {
  background: #fa6800;
}
.sidebar.orange li > a {
  background: #fa6800;
  color: #ffffff;
}
.sidebar.orange li:hover > a {
  background: #df6919;
  color: #ffffff;
}
.sidebar.orange li.active > a {
  background: #bf5a15;
  color: #ffffff;
}
.sidebar.orange li.disabled > a {
  background: #fa6800;
  color: #555555;
}
/*
* params: name, background, backgroundHover, backgroundActive, item, itemHover, itemActive, itemDisabled
*/
.calendar.darcula {
  background: #3C3F41;
}
.calendar.darcula a {
  color: #ffffff;
}
.calendar.darcula a:hover {
  background: #4d5154;
  color: #ffffff;
}
.calendar.darcula .calendar-header {
  background: #3C3F41;
}
.calendar.darcula .today a {
  background-color: #fa6800;
}
.calendar.darcula .day,
.calendar.darcula .month,
.calendar.darcula .year {
  border-color: #4d5154;
}
.calendar.darcula .other-day {
  background: #555555;
  border-color: #555555;
}
.calendar.darcula .day-of-week {
  color: #ffffff;
}
.calendar.darcula .calendar-actions .calendar-btn-today {
  background: #1d1d1d;
  border-color: #1d1d1d;
}
.calendar.darcula .calendar-actions .calendar-btn-today:active {
  background: #030303;
}
.calendar.darcula .calendar-actions .calendar-btn-clear {
  background: transparent;
  border-color: transparent;
}
.calendar.pink {
  background: #dc4fad;
}
.calendar.pink a {
  color: #ffffff;
}
.calendar.pink a:hover {
  background: #b91a6c;
  color: #ffffff;
}
.calendar.pink .calendar-header {
  background: #dc4fad;
}
.calendar.pink .today a {
  background-color: #fa6800;
}
.calendar.pink .day,
.calendar.pink .month,
.calendar.pink .year {
  border-color: #b91a6c;
}
.calendar.pink .other-day {
  background: #f472d0;
  border-color: #f472d0;
}
.calendar.pink .day-of-week {
  color: #ffffff;
}
.calendar.pink .calendar-actions .calendar-btn-today {
  background: #9a165a;
  border-color: #9a165a;
}
.calendar.pink .calendar-actions .calendar-btn-today:active {
  background: #6d1040;
}
.calendar.pink .calendar-actions .calendar-btn-clear {
  background: transparent;
  border-color: transparent;
}
.calendar.navy {
  background: #0072C6;
}
.calendar.navy a {
  color: #ffffff;
}
.calendar.navy a:hover {
  background: #006aba;
  color: #ffffff;
}
.calendar.navy .calendar-header {
  background: #0072C6;
}
.calendar.navy .today a {
  background-color: #fa6800;
}
.calendar.navy .day,
.calendar.navy .month,
.calendar.navy .year {
  border-color: #006aba;
}
.calendar.navy .other-day {
  background: #59cde2;
  border-color: #59cde2;
}
.calendar.navy .day-of-week {
  color: #ffffff;
}
.calendar.navy .calendar-actions .calendar-btn-today {
  background: #16499a;
  border-color: #16499a;
}
.calendar.navy .calendar-actions .calendar-btn-today:active {
  background: #10346d;
}
.calendar.navy .calendar-actions .calendar-btn-clear {
  background: transparent;
  border-color: transparent;
}
.calendar.red {
  background: #CE352C;
}
.calendar.red a {
  color: #ffffff;
}
.calendar.red a:hover {
  background: #b91a1a;
  color: #ffffff;
}
.calendar.red .calendar-header {
  background: #CE352C;
}
.calendar.red .today a {
  background-color: #fa6800;
}
.calendar.red .day,
.calendar.red .month,
.calendar.red .year {
  border-color: #b91a1a;
}
.calendar.red .other-day {
  background: #da5a53;
  border-color: #da5a53;
}
.calendar.red .day-of-week {
  color: #ffffff;
}
.calendar.red .calendar-actions .calendar-btn-today {
  background: #9a1616;
  border-color: #9a1616;
}
.calendar.red .calendar-actions .calendar-btn-today:active {
  background: #6d1010;
}
.calendar.red .calendar-actions .calendar-btn-clear {
  background: transparent;
  border-color: transparent;
}
.calendar.green {
  background: #60a917;
}
.calendar.green a {
  color: #ffffff;
}
.calendar.green a:hover {
  background: #169f2c;
  color: #ffffff;
}
.calendar.green .calendar-header {
  background: #60a917;
}
.calendar.green .today a {
  background-color: #fa6800;
}
.calendar.green .day,
.calendar.green .month,
.calendar.green .year {
  border-color: #169f2c;
}
.calendar.green .other-day {
  background: #7ad61d;
  border-color: #7ad61d;
}
.calendar.green .day-of-week {
  color: #ffffff;
}
.calendar.green .calendar-actions .calendar-btn-today {
  background: #128023;
  border-color: #128023;
}
.calendar.green .calendar-actions .calendar-btn-today:active {
  background: #0c5317;
}
.calendar.green .calendar-actions .calendar-btn-clear {
  background: transparent;
  border-color: transparent;
}
.calendar.orange {
  background: #fa6800;
}
.calendar.orange a {
  color: #ffffff;
}
.calendar.orange a:hover {
  background: #df6919;
  color: #ffffff;
}
.calendar.orange .calendar-header {
  background: #fa6800;
}
.calendar.orange .today a {
  background-color: #fa6800;
}
.calendar.orange .day,
.calendar.orange .month,
.calendar.orange .year {
  border-color: #df6919;
}
.calendar.orange .other-day {
  background: #ffc194;
  border-color: #ffc194;
}
.calendar.orange .day-of-week {
  color: #ffffff;
}
.calendar.orange .calendar-actions .calendar-btn-today {
  background: #bf5a15;
  border-color: #bf5a15;
}
.calendar.orange .calendar-actions .calendar-btn-today:active {
  background: #914410;
}
.calendar.orange .calendar-actions .calendar-btn-clear {
  background: transparent;
  border-color: transparent;
}
