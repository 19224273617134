*[dir=ltr] {
  direction: ltr;
  unicode-bidi: embed;
}
*[dir=rtl] {
  direction: rtl;
  unicode-bidi: embed;
}
bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}
bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
*[dir=rtl] ul,
*[dir=rtl] ol,
ul[dir=rtl],
ol[dir=rtl] {
  margin-right: .3125rem;
  margin-left: 0;
  padding-right: .625rem;
}
*[dir=rtl] ul li ul,
*[dir=rtl] ol li ul,
ul[dir=rtl] li ul,
ol[dir=rtl] li ul,
*[dir=rtl] ul li ol,
*[dir=rtl] ol li ol,
ul[dir=rtl] li ol,
ol[dir=rtl] li ol {
  padding-right: 1.5625rem;
  padding-left: 0;
}
*[dir=rtl] dl dd,
dl[dir=rtl] dd {
  margin-right: .9375rem;
  margin-left: 0;
}
*[dir=rtl] dl.horizontal dt,
dl[dir=rtl].horizontal dt {
  float: right;
  width: 10rem;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}
*[dir=rtl] dl.horizontal dd,
dl[dir=rtl].horizontal dd {
  margin-right: 11.25rem;
  margin-left: 0;
}
*[dir=rtl] blockquote,
blockquote[dir=rtl] {
  margin: .625rem 0;
  padding: 0 .625rem 0 0;
  border-left: none;
  border-right: 0.25rem #999999 solid;
}
*[dir=rtl] .input-control.text .button,
.input-control[dir=rtl].text .button,
*[dir=rtl] .input-control.select .button,
.input-control[dir=rtl].select .button,
*[dir=rtl] .input-control.file .button,
.input-control[dir=rtl].file .button,
*[dir=rtl] .input-control.password .button,
.input-control[dir=rtl].password .button,
*[dir=rtl] .input-control.number .button,
.input-control[dir=rtl].number .button,
*[dir=rtl] .input-control.email .button,
.input-control[dir=rtl].email .button,
*[dir=rtl] .input-control.tel .button,
.input-control[dir=rtl].tel .button,
*[dir=rtl] .input-control.text .button-group,
.input-control[dir=rtl].text .button-group,
*[dir=rtl] .input-control.select .button-group,
.input-control[dir=rtl].select .button-group,
*[dir=rtl] .input-control.file .button-group,
.input-control[dir=rtl].file .button-group,
*[dir=rtl] .input-control.password .button-group,
.input-control[dir=rtl].password .button-group,
*[dir=rtl] .input-control.number .button-group,
.input-control[dir=rtl].number .button-group,
*[dir=rtl] .input-control.email .button-group,
.input-control[dir=rtl].email .button-group,
*[dir=rtl] .input-control.tel .button-group,
.input-control[dir=rtl].tel .button-group {
  right: auto;
  left: 0;
}
*[dir=rtl] .input-control.text .prepend-icon,
.input-control[dir=rtl].text .prepend-icon,
*[dir=rtl] .input-control.select .prepend-icon,
.input-control[dir=rtl].select .prepend-icon,
*[dir=rtl] .input-control.file .prepend-icon,
.input-control[dir=rtl].file .prepend-icon,
*[dir=rtl] .input-control.password .prepend-icon,
.input-control[dir=rtl].password .prepend-icon,
*[dir=rtl] .input-control.number .prepend-icon,
.input-control[dir=rtl].number .prepend-icon,
*[dir=rtl] .input-control.email .prepend-icon,
.input-control[dir=rtl].email .prepend-icon,
*[dir=rtl] .input-control.tel .prepend-icon,
.input-control[dir=rtl].tel .prepend-icon {
  left: auto;
  right: 4px;
}
*[dir=rtl] .input-control.text .prepend-icon ~ input,
.input-control[dir=rtl].text .prepend-icon ~ input,
*[dir=rtl] .input-control.select .prepend-icon ~ input,
.input-control[dir=rtl].select .prepend-icon ~ input,
*[dir=rtl] .input-control.file .prepend-icon ~ input,
.input-control[dir=rtl].file .prepend-icon ~ input,
*[dir=rtl] .input-control.password .prepend-icon ~ input,
.input-control[dir=rtl].password .prepend-icon ~ input,
*[dir=rtl] .input-control.number .prepend-icon ~ input,
.input-control[dir=rtl].number .prepend-icon ~ input,
*[dir=rtl] .input-control.email .prepend-icon ~ input,
.input-control[dir=rtl].email .prepend-icon ~ input,
*[dir=rtl] .input-control.tel .prepend-icon ~ input,
.input-control[dir=rtl].tel .prepend-icon ~ input {
  padding-left: 0;
  padding-right: 30px;
}
*[dir=rtl] .grid .row > .cell {
  float: right;
}
