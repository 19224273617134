
:root {
  /*--bgheader: #086A87;*/
  --bgheader: #1565c0;
  --bgcardlightprimary: #ffee93;
  --bgcardlightsecondary: #a3d2ca;
  --bgturquoise: #a3ddcb;
  --bgyellow: #e8e9a1;
  --bgorange: #e6b566;
  --bgredpastel: #e5707e;
  --bgnested: #e5e5e5;
  --bgfilter: #feeee2;
  --valign: middle;
}

my-date-picker {
    width: 95%
}

my-date-picker > div {
  margin-top: 0.5rem !important;
}

a:hover:active {
  color: var(--bgheader);
}

.scm {
  /*margin: 0.5rem !important;*/
}

.scm-action{
    cursor: pointer;
}

div.scm > div {
  margin: 0.5rem 0;
}

hr {
  margin: 0.5rem 0;
}

.scm-title-grid {
  background-color: var(--bgheader);
  padding: 0.5rem;
  color: white;
}

.scm-card-title {
  background-color: var(--bgheader);
  border-radius: 5px;
  padding: 0.5rem;
  color: white;
  opacity: 0.9;
}

.scm-card-detail {
  /*border-radius: 5px;*/
  padding: 0;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
}

.scm-card-detail > p:first-child{
    font-weight: 500;
}

.scm-card-detail-border {
  border: 0.1rem solid lightgray;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.scm-flex-container-title {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.scm-flex-container-cards {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.scm-flex-container-cards > div:nth-child(2n){
    width: calc(100% - 41rem);
}

.scm-flex-container {
  display: flex;
  justify-content: left;
  align-items: center;
}

.scm-flex-container-cl {
  display: flex;
  flex-direction: column;
  align-items: center;
}

scm-flex-container-center {
  display: flex;
  justify-content: normal;
  align-items: center;
}

.scm-flex-container-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*margin: 0.5rem 0;*/
}

.scm-flex-form-with-details {
  display: flex;
  justify-content: space-between;
}

/*.scm-flex-form-with-details > div:first-child {
  width: 43%;
}*/

/*.scm-flex-form-with-details > div:not(:first-child) {
  width: 20%
}*/

.scm-flex-form-with-details > div:not(:last-child){
  align-items: baseline;
}

.scm-flex-form-with-details > div:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.scm-flex-form-with-details div.scm-flex-container div.scm-flex-card {
    margin: 0 0.3rem;
}

.scm-flex-form {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.scm-flex-form > div {
    flex-direction: column;
}

.scm-flex-form > div:first-child {
  width: 50rem;
}

.scm-flex-form-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.scm-flex-form-detail > div{
    display: flex;
    align-items: center;
}

.scm-flex-form-detail-item{

}

.scm-flex-form-detail-item > div:last-child {
    padding: 0.5rem;
    width: 100%;
}

.scm-flex-form-detail-item > div:last-child input, .scm-flex-form-detail-item > div:last-child > div {
  width: 95%;
}

.scm-flex-container-actions > div:first-child {
  display: flex;
  align-items: center;
}

.scm-flex-container-actions div.scm-container-right {
    justify-content: end;
}

.scm-flex-container-graph-filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.scm-flex-container > div.scm-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}


.scm-flex-container > div.scm-container-filters-input {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.scm-flex-container > div.scm-container-dos, ul.scm-container-dos {
  width: calc(100%/2);
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.scm-flex-container-filters {
  display: flex; /*!important;*/
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5rem;
}

.scm-flex-container-filters > div:first-child {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.scm-flex-container-filters > div.scm-right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.scm-flex-right {
    justify-content: flex-end!important;
}

.scm-container-filters-input > ng-select, .scm-container-dos > ng-select, .scm-flex-container > ng-select {
  width: 12rem !important;
}

.scm-flex-container > div.scm-card-detail {
  padding: 0.5rem;
}

.cell.scm-flex-container {
  padding-top: 3.5rem;
}

.scm-flex-card{
    flex-direction: column;
    align-items: center;
    width: 10rem;
}

/*.scm-flex-card div:not(:last-child) {
    margin: 0 0.5rem 0 0;
}*/

.scm-flex-card-detail > p{
  width: 0.2rem;
  min-height: 3.5rem;
  border-left: 1px solid var(--bgheader);
  display: inline;
  height: 100%;
  margin: 0.2rem;
}

.scm-flex-card > div.scm-flex-card-detail:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scm-flex-card > div.scm-flex-card-detail:last-child div {
  width: calc(9.8rem/2);
}

.scm-card-estandar-detail-title {
  background-color: var(--bgheader);
  color: white;
  padding: 0.3rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
}

.scm-flex-card > div:first-child {
  background-color: white;
  color: var(--bgheader);
  padding: 0.3rem;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.875rem;
}

.scm-card-estandar-detail {
  background-color: white;
  /*opacity: 0.8;*/
  border-radius: 0 0 5px 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

div.scm-card-estandar-detail > p:first-child{
    font-weight: 600;
    color: var(--bgheader);
}

.scm-card-estandar {
  background-color: white;
  opacity: 0.8;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.scm-card-estandar > div:first-child {
  /*background-color: var(--bgheader);
  color: white;*/
  padding: 0.3rem;
  border-radius: 5px 5px 0 0;
}

/*.scm-flex-container-cards > div.scm-card-detail, div.scm-card-detail-border {
  width: calc((100%-10rem)/2)
}*/

.scm-forecast-dos {
    width: 15%;
}

.scm-forecast {
  flex-direction: column;
  justify-content: center;
  align-items: normal;
}

.scm-forecast > div {
    margin: 0.2rem;
}

.scm-card-group {
  display: flex !important;
  justify-content: space-between;
}

.scm-card-group > div {
    width: calc(100%/3);
    margin: 0.2rem;
}

.scm-card-border {
  border-radius: 5px;
  border-color: lightgray;
  border-style: solid;
  border-width: 0.1rem;
  color: gray;
  padding: 0.3rem;
}

.scm-list-informativo {
    height: 6rem!important;
    overflow-y: scroll!important;
}

.scm-card-detail-title {
  padding: 0.2rem;
  margin-bottom: 0.2rem !important;
}

div.scmBtnGroup div {
  display: inline-block;
}

.scm-graph-and-filters-v:first-child {
    width: calc(100%-65rem);
}

.scm-graph-and-filters-v > div.scm-container-graph {
    max-width: 65rem;
    width: 65rem;
    overflow-x: scroll;
}

div.scm-filters-h-graph {
  display: flex !important;
  align-items: flex-start;
  flex-wrap: wrap;
}

div.scmFiltrosV div {
  margin: 0.2rem 0.5rem !important;
}

div.scm-column-grid > p{
    margin: 0.5rem 0 0;
    font-weight: 500;
}

/*.scm-ocultar-componente {
  visibility: hidden;
}

.scm-mostrar-componente {
  visibility: visible;
}*/

.scm-debug {
  border: 1px red dashed;
  background-color: beige;
}

.scm-div-informativo-title {
  padding: 0.3rem;
  margin: 0.5rem;
  background-color: var(--bgheader) !important;
  border-radius: 5px;
  color: white;
  border-color: var(--bgheader) !important;
  border-style: solid;
  border-width: 0.1rem;
}

.scm-filters-area {
    border: 1px dashed gray;
}

.scm-ejex-meses {
  text-align: center;
}

.scm-ejex-semanas {
  text-align: center;
}

div.cell div.scm-ejex-meses ~ div {
  margin-left: 1.5rem !important;
}

div.cell div.scm-ejex-semanas ~ div:nth-child(4n+1) {
  margin-left: 1.6rem !important;
}

svg.scm-graph-mensual rect.scm-bar {
  /*fill: var(--bgheader);*/
  /*stroke-opacity: 0.8;
  stroke: darkslategray;
  stroke-width: 1;*/
  transition: fill 250ms;
  /*fill-opacity: 0.8;*/
}

rect.scm-series-meses {
  fill: lightgray;
  color: black;
}

rect.scm-series-semanas {
  fill: white;
  color: black;
}

.scm-series-semanas-ind {
  fill: lightgray;
  color: black;
  stroke: black;
  stroke-opacity: 0.5;
}

svg.scm-graph-mensual line {
  stroke: black;
  stroke-width: 1;
}

.scm-div-informativo-detalle {
  padding: 0.3rem;
  margin: 0.5rem;
  background-color: lightgray;
  border-radius: 5px;
  color: black;
  border-color: dimgray;
  border-style: solid;
  border-width: 0.1rem;
}

div.cell div.scm-bgrafico-mensual ~ div {
  margin-left: 1.5rem !important;
}

.scm-div-informativo {
  display: inline-block;
}

.scm-seccion-informativo {
  display: inline-block;
  width: 3rem;
}

div.scm-detalle-seccion {
  height: 3rem !important;
}

div.scm-detalle-seccion rect:first-child {
  fill: var(--bgheader);
}

rect ~ text.scm-section-title {
  fill: white;
}

/*text.scm-section-title {
  color: white;
}*/


.scm-bgrafico-mensual {
  padding: 0.3rem;
  margin: 0.5rem;
  background-color: var(--bgheader);
  color: white;
  border-color: dimgray;
  border-style: solid;
  border-width: 0.1rem;
}

.scm-div-ejexy {
  border-color: white white dimgray dimgray;
  border-style: solid;
  border-width: 0.15rem;
}

.bg-primary-card-detail {
  background-color: var(--bgcardlightprimary);
  opacity: 0.8;
  border-radius: 8px;
}

.bg-secondary-card-detail {
  /*background-color: var(--bgcardlightsecondary);*/
  background-color: white;
  opacity: 0.8;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.bg-secondary-card-detail > div:first-child{
    background-color: var(--bgheader);
    color: white;
    padding: 0.3rem;
    border-radius: 8px 8px 0 0;
}

.bg-total-factual {
  background-color: var(--bgturquoise);
  opacity: 0.8;
  border-radius: 8px;
}

.bg-total-fnuevo {
  background-color: var(--bgyellow);
  opacity: 0.8;
}

.bg-fsolicitud {
  background-color: var(--bgredpastel);
  opacity: 0.8;
}

.scm-title-component {
  color: var(--bgheader);
}

ng-select.scm-ngselect {
  display: inline-block !important;
  width: 70%;
}

/**Grid propio usando estilos sobreescritos para el grid de jqwidgets*/
div.scm-grid {
  font-family: Verdana,Arial,sans-serif!important;
}
div.scm-grid > div:first-child {
  background-color: var(--bgheader);
  color: white;
  font-size: 13px;
}

div.scm-grid-columns{
  display: flex;
  align-items: center;
  height: 4rem;
}

/*div.scm-grid-columns > div {
  height: 4rem;
}*/

div.scm-nested-data {
  border-width: 0.2px 0 0 0.2px;
  border-style: solid;
  border-color: lightgray;
}

div.scm-column-grid {
  display: inline-block !important;
  font-weight: 600;
}

div.scm-column-data-grid {
  display: inline-block !important;
  padding: 0.3rem;
}

.scm-column-group-grid-bg {
  border: 1px solid var(--bgheader);
  padding: 0;
  margin: 0.3rem;
  border-radius: 2px;
  background-color: var(--bgheader);
  color: white;
  font-weight: 600;
}

.scm-column-group-grid {
  border: 1px solid white;
  padding: 0;
  margin: 0.3rem;
  border-radius: 2px;
  background-color: white!important;
  color: black;
  font-weight: 600;
}
.scm-column-group-grid-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scm-column-group-grid-icon span:last-child{
    position: absolute;    
}

.scm-column-grid:hover {
  cursor: pointer;
}

.scm-column-grid:hover .scm-column-grid>img {
  visibility: visible!important;
}

.scm-column-group-grid p {
  margin: 0.4rem;
  /*font-weight: 500;*/
}


div.scm-column-grid > input {
  border: 1px solid lightgray;
  height: 1.6rem;
}

div.scm-data-grid {
    border-color: lightgray;
    border-style: solid;
    border-width: 0.2px 0.2px 0 0.2px;
    min-height: 2rem;
    align-items: center;
}

/*div.scm-data-grid div:first-child {
  text-align: left;
}*/

div.scm-data-grid:last-child {
  border: 0.2px solid lightgray;
}

div.scm-grid div.scm-data-grid:nth-child(2n) {
  background-color: #f9f9f9 !important;
}

div.scm-grid div.scm-data-grid:nth-child(2n+1) {
  background-color: white !important;
}

div.scm-grid div.scm-data-grid:hover {
  background-color: #ECECEC !important; /*#EFEFEF*/
}

div.scm-pagination ul.ngx-pagination {
    margin: 0!important;
}


div.scm-pagination ul.ngx-pagination li,a{
  color: white!important;
}

div.scm-pagination ul.ngx-pagination a:hover {
  color: black!important;
}

.scm-pinned-column-body {
  position: sticky !important;
  z-index: 1 !important;
  background-color: var(--bgnested) !important;
  vertical-align: var(--valign);
  min-height: 2rem;
  /*padding-top: 0.5rem;*/
}

.scm-pinned-column-body > p {
    margin: 0.2rem!important;
}

.scm-pinned-column-filter-body {
  position: sticky !important;
  z-index: 1 !important;
  vertical-align: var(--valign);
  background-color: var(--bgfilter) !important;
  /*height: 2rem;*/
}

.scm-pinned-column-header {
  position: sticky !important;
  z-index: 1 !important;
  background-color: var(--bgheader) !important;
}

.scm-header-remaining-columns {
  background-color: var(--bgheader) !important;
}

.scm-header-remaining-columns div:last-child {
  display: flex;
  align-items: center;
}

.scm-nested-detail-grid {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.scm-nested-detail-grid > div {
  margin: 0.2rem;
  padding: 0.3rem;
}

.scm-nested-detail-grid > div:nth-child(3n) {
    background-color: lightsteelblue;
    opacity: 0.8;
    color: black;
    border-radius: 0.3rem
}

.scm-nested-detail-grid div:last-child{
  width: 10rem;
}
/*div.scm-grid div.scm-data-grid:nth-child(3n+2) {
  background-color: white !important;
}*/
/**FIN Grid propio usando estilos sobreescritos para el grid de jqwidgets*/
div.scm-form-title p{
    font-weight: 600;
    margin: 0.2rem;
}
.scm-blackpopover {
  padding: .50rem;
  /*margin: 0.5rem;*/
  max-width: 25rem;
  min-width: 25rem;
  /*min-height: 8rem;*/
  max-height: 8rem;
  z-index: 999;
  color: black;
  position: absolute !important;
  /*opacity: 0.8;*/
  overflow: auto;
  background-color: white;
  box-shadow: 2px 4px 8px 2px rgba(0,0,0,0.4);
  transition: 0.3s;
  border-radius: 5px;
  /*top: -1.5rem;*/
  left: auto;
  right: 7%;
  border: 1px solid lightgray;
}

.BlackpopoverChat {
  /*opacity: 0.8;*/
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  border: 1px solid lightgray;
}

/*.Blackpopover.scm-chat{
    background: white;
    border-radius: 0 5px 5px 5px;
    float: left;
  }*/

.scm-chat:before {
  content: "";
  width: 0px;
  height: 0px;
  position: relative;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 0 solid white;
  border-bottom: 1rem solid white;
  /*right: 19px;*/
  top: -0.5rem;
  left: 4rem;
}

/*.scm-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}*/

.scm-tooltip {
  /*visibility: hidden;*/
  width: 25%;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  /*top: auto;*/
  left: 77.7%;
  margin-left: -95px;
  box-shadow: 2px 4px 8px 2px rgba(0,0,0,0.4);
}


.scm-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: 40%;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.scm-tooltip-right {
  /*visibility: hidden;*/
  width: 25%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 74%;
  margin-left: -60px;
}

.scm-tooltip-right::after {
  content: " ";
  position: absolute;
  top: 78%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.1);
}


/*Notification with icon*/
.scm-notification {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.scm-notification .scm-badge {
  position: absolute;
  top: 15px;
  right: -10px;
  padding: 0.3rem;
  border-radius: 50%;
  background: red;
  color: white;
}

div.scm-notification:hover {
    transform: scale(1.2);
  /*box-shadow: -15px -15px 20px 0 rgba(0,0,0,.4) inset, 5px 5px 5px 0 rgba(255,255,255,.5) inset, 5px 5px 5px 0 rgba(255,255,255,.5);*/
}
/*End notification with icon*/

/*.scm-popover:hover .scm-tooltip {
  visibility: visible;
}*/

.ul {
  list-style-type: none;
  text-align: left;
  font-size: 14px;
}

.scm-filters-months-icons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.scm-active-filters {
  /*height: 60px;
  width: 60px;*/
  border-radius: 0.3rem;
  border: none;
  background-color: #4B8BD1;
  color: white;
  text-align: center;
  font-size: 0.8rem;
  margin: 0 0.5rem;
  padding: 0.2rem;
  box-shadow: -15px -15px 20px 0 rgba(0,0,0,.4) inset, 5px 5px 5px 0 rgba(255,255,255,.5) inset, 5px 5px 5px 0 rgba(255,255,255,.5);
}


@media (max-width: 800px) {
  .scm > div {
      width: 100%!important;
  }

  .scm-flex-form, .scm-flex-form div.scm-flex-form-detail, .scm-flex-form-with-details {
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
  }

  div.scm-flex-form-detail > div {
      width: 95%!important;
  }

  .scm-flex-form div.scm-forecast {
    flex-direction: initial;
  }

  .scm-flex-form-with-details > div{
      width: 100%
  }

  .scm-flex-form-with-details > div:first-child{
    justify-content: space-between
  }

  /*.scm-flex-form-with-details > div:first-child > div{
    width: calc(100% / 3)
  }

    .scm-flex-form-with-details > div:first-child > div > div {
      width: calc(100% / 2)
    }*/

  .scm-flex-container .scm-forecast {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .scm-flex-select {
    flex-direction: column !important;
    align-items: flex-start;
  }

  .scm-flex-container-filters, .scm-flex-container-cl {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .scm-filters-area {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .scm-flex-container-filters > div.scm-filters-months-icons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .scm-flex-container-filters > div.scm-filters-months-icons > div {
    padding: 0.3rem;
  }

  .scm-flex-container-filters > div {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .scm-flex-container > div.scm-container-dos, .scm-flex-container > div.scm-container-filters-input, .scm-flex-container-graph-filters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .scm-flex-container-graph-filters > ul.scm-filters-graph, .scm-flex-container-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  
  .scm-flex-container {
       padding: 0
  }


  /*.scm-flex-container-filters > div.scm-flex-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

  .scm-flex-container-filters  > div:last-child {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }*/
}

/*icons*/

/*end icons*/

/*#00bfb6*/
